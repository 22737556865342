import React from 'react';

const TableLIScriptInfo = (props) => {
    const { description, local, page, type, content } = props;
    return (
        <div className="table-li-info-install-script">
            <p>Em caso de dúvidas sobre como criar um <u>Código HTML</u> na <b>Loja Integrada</b> <a href="https://comunidade.lojaintegrada.com.br/t/dicas-basicas-css-e-html/17789" rel="noreferrer" target="_blank">clique aqui</a>.</p>
            <table className="table table-striped">
                <tbody>
                    <tr>
                        <th>Descrição:</th>
                        <td>{description}</td>
                    </tr>
                    <tr>
                        <th>Local publicação:</th>
                        <td>{local}</td>
                    </tr>
                    <tr>
                        <th>Página publicação:</th>
                        <td>{page}</td>
                    </tr>
                    <tr>
                        <th>Tipo:</th>
                        <td>{type}</td>
                    </tr>
                    <tr>
                        <th>Conteúdo:</th>
                        <td>{content}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TableLIScriptInfo;