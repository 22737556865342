import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import toast from 'react-hot-toast';
import { history } from '../../../services/history';

const PageAdminInstafeedAuth = (props) => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const [loading, setLoading] = useState(false);

    const storeApp = JSON.parse(localStorage.getItem('instafeed_appstore') ?? '{}');

    useEffect(() => {
        async function fetchData(){
            await api.post(`/facebook/ig/get-token`, { code }, {
                headers: {
                    'appToken': storeApp.token
                }
            })
                .then( resp => {
                    try {
                       const { data } = resp;
                        if( 'ig_access_token' in data ){
                            toast.success(`Autorização realizada com sucesso!`);
                        }else{
                            toast.error(`Erro ao autorizar o App: ${data.error}`);
                        }
                    } catch (err) {
                        toast.error(`Erro ao autorizar app, erro não identificado`);
                    }
                    
                }).catch( err => {
                    try {
                        const { error } = err.response.data;
                        toast.error(`Erro ao autorizar app: ${error}`);
                    } catch (err) {
                        toast.error(`Erro ao autorizar app, erro não identificado`);
                    }
                }).then( () => {
                    setLoading(false);
                    return history.push(`/admin/apps/editor/${storeApp.id}`)
                })
        }
        if( !loading && code && storeApp.id && storeApp.token ){
            fetchData();
        }
    }, [code, loading, storeApp]);

    return (
        <div>
            Aguarde...
        </div>
    )
}

export default PageAdminInstafeedAuth;