import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../services/api';
import ComponentAdminContent from '../../../components/Admin/AdminContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import SVGIcon from '../../../components/UI/SVGIcon';

const PageAdminStoreSubscriptions = () => {

    const { storeId } = useParams();
    const [Loading, setLoading] = useState(true);
    const [AppStores, setAppStores] = useState([]);
    const [subscription, setSubscription] = useState({});
    const [appStoreId, setAppStoreId] = useState(null);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false); 
    const handleShow = async (id) => {
        
        await api.get(`/app-store/${id}/uigu/subscriptions-detail`)
            .then( resp => {

                if( resp.data.error ){
                    toast.error('Nenhuma assinatura encontrada');
                    setSubscription({});
                    return false;
                }
                setAppStoreId(id)
                setSubscription(resp.data);
                setShow(true);
            })
            .catch( err => {
                toast.error('Erro ao consultar assinatura');
                setSubscription({});
            })
    }
    const handleSuspend = async (id) => {
        
        await api.post(`/app-store/${id}/uigu/subscriptions/suspend`)
            .then( resp => {
                setSubscription(resp.data);
                setLoading(true);
                toast.success('Assinatura suspensa');
            })
            .catch( err => {
                console.log(err);
                toast.error('Erro ao suspender assinatura');
            })
        }
        const handleActivate = async (id) => {
            
            await api.post(`/app-store/${id}/uigu/subscriptions/activate`)
            .then( resp => {
                setSubscription(resp.data);
                setLoading(true);
                toast.success('Assinatura ativa');
            })
            .catch( err => {
                console.log(err);
                toast.error('Erro ao ativar assinatura');
            })
    }

    useEffect(() => {
        async function fetchData(){
            await api.get(`/app-store/by-store/${storeId}`)
                .then( resp => {
                    
                    const { data } = resp;
                    console.log(data)
                    try {
                        if( data.length ){
                            setAppStores(data);
                            return true;
                        }
                    } catch (error) {
                        
                    }

                }).catch( err => {
                    try {
                        toast.error('Erro ao carregar os apps das loja');
                        console.log('erro app/stores', err);
                    } catch (err) {
                        toast.error('Erro ao tentar carregar os apps das loja');
                        console.log('erro try app/stores', err);
                    }
                }).then( () => {
                    setLoading(false);
                })
        }
        if( Loading ){
            fetchData();
        }
    }, [storeId, Loading]);

    if( Loading ){
        return (
            <p>Aguarde...</p>
        )
    }

    return (
        <ComponentAdminContent 
            title={<><SVGIcon icon={'store'} width="29" height="29" />  Meus Apps x Assinaturas</>}
            titleExtra={
                <Button onClick={() => window.history.go(-1)} variant="default" size="sm" className="me-1"><FontAwesomeIcon icon={['fas', 'arrow-left']} fixedWidth className="me-1" /> VOLTAR</Button>
            }
        >
            <div className="my-app-store">
                <div className="row">
                    {
                        !AppStores
                        ? <p>Sem registros</p>
                        : AppStores.map( (value, key) => {
                            return (
                                <div key={key} className="col-12 col-md-4 mb-4">
                                    <Card className="my-app-store--item">
                                        <Card.Header>
                                            <Card.Title>{value.app.name.pt}</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <dl className="row">
                                                <dt className="col-4">Situação</dt>
                                                <dd className="col-8">{value.active?'Liberado': 'Bloqueado'}</dd>
                                                <dt className="col-4">Status</dt>
                                                <dd className="col-8">{value.in_use?'App em execução': 'App pausado'}</dd>
                                                <dt className="col-4">Assinatura</dt>
                                                <dd className="col-8">{value.subscriptionId?'Com assinatura': 'Sem assinatura'}</dd>
                                            </dl>
                                        </Card.Body>
                                        <Card.Footer>
                                            { value.subscriptionId 
                                                ? <Button variant="default" className="d-block w-100" size="sm" onClick={() => handleShow(value._id)}>Historico da Assinatura</Button>
                                                : <Button variant="secondary" className="d-block w-100" size="sm" disabled={true}>Sem assinatura</Button>
                                            }
                                        </Card.Footer>
                                    </Card>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg" id="my-subscription-modal" className="my-subscription-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Minha Assinatura - {subscription.plan_name??''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <ul className="my-subscription-modal--info">
                                <li><b>Em trial?</b> <strong>{subscription.in_trial?'Sim':'Não'}</strong></li>
                                <li><b>Ativo?</b> <strong>{subscription.active?'Sim':'Não'}</strong></li>
                                <li><b>Suspenso?</b> <strong>{subscription.suspended?'Sim':'Não'}</strong></li>
                                <li><b>Valor</b> <strong>R$ {(subscription.price_cents/100)??'0,00'}</strong></li>
                                <li><b>Validade</b> <strong>{subscription.expires_at??'---'}</strong></li>
                                <li><b>Qtd. ciclos</b> <strong>{subscription.cycles_count??'0'}</strong></li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-7">
                            <div className="scroll-y">
                                <div className="scroll-y-effect">
                                    {
                                        !subscription.recent_invoices
                                        ? <p className="alert alert-warning">Nenhuma fatura encontrada.</p>
                                        : 
                                        <div className="my-subscription-modal--invoices">
                                            <h6>Faturas</h6>
                                            {
                                                subscription.recent_invoices.map( (value, key) => {
                                                    return (
                                                        <p key={key}>
                                                            <b>{value.status}:</b> <span>{value.due_date} - {value.total}</span>
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        !subscription.logs
                                        ? <p className="alert alert-warning">Nenhum log de assinatura registrado.</p>
                                        : 
                                        <div className="my-subscription-modal--logs">
                                            <h6>Logs</h6>
                                            {
                                                subscription.logs.map( (value, key) => {
                                                    return (
                                                        <p key={key}>
                                                            <b>{value.description}:</b> <br/><span>{value.notes}</span>
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    {
                        subscription.suspended
                        ? <Button variant="success" onClick={()=>handleActivate(appStoreId)}> REATIVAR ASSINATURA</Button>
                        : <Button variant="danger" onClick={()=>handleSuspend(appStoreId)}> SUSPENDER ASSINATURA</Button>
                    }
                </Modal.Footer>
            </Modal>
        </ComponentAdminContent>
    )
}

export default PageAdminStoreSubscriptions;