import React from 'react';
import { Toaster } from 'react-hot-toast';
import ComponentAdminHeaderContent from './Header/HeaderContent';
import ComponentAdminNavContent from './Nav/NavContent';

const ComponentAdminContent = (props) => {
    
    return (
        <div id="wrapper">
            <ComponentAdminHeaderContent />
            <ComponentAdminNavContent />
            <div className="main">
                <div className="main-content">
                    {
                        props.title || props.subtitle
                            ? (
                                <div className="content-heading d-flex justify-content-between align-items-center">
                                    <div className="heading-left d-flex align-items-center flex-wrap">
                                        <h2 className="page-title">{props.title}</h2>
                                        {
                                            props.subtitle
                                            ? <h3 className="page-subtitle ms-3">{props.subtitle}</h3>
                                            : ''
                                        }
                                    </div>
                                    <div className="heading-right">
                                        {props.titleExtra}
                                    </div>
                                </div>
                            )
                            : ''
                    }
                    <div className='container-fluid'>
                        {props.children}
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </div>
    )
}

export default ComponentAdminContent;