import React from 'react';
import Brand from '../UI/Brand';
import { Toaster } from 'react-hot-toast';

const ComponentAuthContent = (props) => {
    const { title, aditionalClass } = props;
    return (
        <section className={`vertical-align-wrap ${aditionalClass}`}>
            <div className="vertical-align-middle">
                <div className="auth-box row align-items-center">
                    <div className="col-left col-md-5">
                        
                        <div className="auth-box--header">
                            <Brand />
                            <p>{title}</p>
                        </div>

                        <div className="auth-box--body">
                            {props.children}
                        </div>
                        
                    </div>
                    <div className="col-right col-md-7">
                        <div className="auth-box--message">
                            <h1>Aqui estão as melhores <b>soluções</b> e <b>apps</b> para revolucionar seu <u>e-commerce</u>!</h1>
                            <p>por Admake Tecnologia</p>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </section>
    )
}

export default ComponentAuthContent;