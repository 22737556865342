import React, { useState, useContext, useEffect } from 'react';
import api from '../services/api';
import toast from 'react-hot-toast';
import { history } from '../services/history';
// import { useCookies } from 'react-cookie';

import { Context } from '../context/AuthContext';
import Loader from '../components/UI/Loader';

const UserContext = React.createContext({User: {}, Stores: [], ActiveStore: {}});

function getLocalActiveStore(){
    try {
        var active_store = localStorage.getItem('active_store');
        if( active_store !== undefined && active_store !== null ){
            active_store = JSON.parse(active_store);
        }
        return active_store;
    } catch (error) {
        console.warn(error);
        return {}
    }
}

const UserProvider = (props) => {
    // const [cookies, setCookies] = useCookies(['active_store']);

    // console.log(cookies.active_store)
    const [User, SetUser] = useState({});
    const [Stores, SetStores] = useState([]);
    // const [ActiveStore, SetActiveStore] = useState( cookies.active_store );
    const [ActiveStore, SetActiveStore] = useState( getLocalActiveStore() );
    const [ActiveStoreId, setActiveStoreId] = useState(null);
    const [loadingUser, setLoadingUser] = useState(false);

    // const [reloadUser, setReloadUser] = useState(true);
    
    const { handleLogout, authenticated, loading } = useContext(Context);

    useEffect(() => {
        const ChangeActiveStore = async (id, showMessage=true) => {
            await api.get(`/stores/${id}`)
                .then( resp => {
                    
                    const { data } = resp;
    
                    if( data._id ){
                        SetActiveStore(data)
                        // setCookies('active_store', data)
                        localStorage.setItem('active_store', JSON.stringify(data));
    
                        if( showMessage ){
                            toast.success('Loja selecionada.');
                        }
                    }else{
                        toast.error('Loja não encontrada.');
                    }
    
                }).catch( err => {
                    toast.error('Erro ao selecionar loja');
                    console.log('erro active store', err);
                }).then( () => {
                    
                })
        }
        if( ActiveStoreId !== null ){
            ChangeActiveStore(ActiveStoreId)
        }
    }, [ActiveStoreId, SetActiveStore]);

    useEffect(() => {
        async function fetchData(){
            // setReloadUser(false);
            const user = localStorage.getItem('user') || null;

            if( user === undefined || user === null ){
                console.log('User not found')
                setLoadingUser(false);
                history.push('/auth/login')
            }
            await api.get(`/user/${user}/complete`)
                .then( resp => {
                    
                        const { user, stores } = resp.data;
                        
                        SetUser(user);
                        SetStores(stores ?? []);
                        setLoadingUser(false);
                        
                        if( !user._id ){
                            handleLogout();
                        }
                        
                        if( !stores.length ){
                            history.push('/admin/store/create')
                        }else if( stores.length === 1 && ( !ActiveStore || !ActiveStore._id ) ){
                            setActiveStoreId(stores[0]['_id']);
                        }
                        
                    }).catch( err => {
                        try {
                            const { error } = err.response.data;
                            toast.error(error);
                            console.log('erro user', err);
                        } catch (err) {
                            console.log('erro try user', err);
                        }
                        setLoadingUser(false);
                        handleLogout();
                    })
                }
        if( !loading && authenticated ){
            setLoadingUser(true);
            fetchData();
        }
    }, [loading, authenticated, handleLogout, ActiveStore, setActiveStoreId]);

    if( loadingUser ){
        return (
            <UserContext.Provider value={{User, Stores, ActiveStore, setActiveStoreId}}>
                <Loader type="page" />
            </UserContext.Provider>    
        )
    }

    return (
        <UserContext.Provider value={{User, Stores, ActiveStore, setActiveStoreId}}>
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }