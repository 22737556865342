import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { history } from '../../services/history';
import toast from 'react-hot-toast';
import api from '../../services/api'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ComponentAuthContent from '../../components/Auth/AuthContent';


const PageAuthConfirmEmail = () => {

    const { token } = useParams();
    
    useEffect( () => {
        async function fetchData() {
            await api.post('/auth/confirm-email', { activateToken: token })
                .then(resp => {
                    toast.success('E-mail confirmado');
                    localStorage.removeItem('reset-password-email');
                }).catch( err => {
                    const { error } = err.response.data;
                    toast.error(`Erro ao confirmar e-mail, tente novamente - ${error}`);
                }).then( () => {
                    history.push('/auth/login');
                });
        }
        fetchData();
    }, [token]);
    
    return (
        <ComponentAuthContent title="Confirmando e-amil" aditionalClass="reset-password-page">
            <p className="text-center"><FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> Aguarde...</p>
        </ComponentAuthContent>
    )
}

export default PageAuthConfirmEmail;