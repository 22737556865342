import React, { useEffect, useState } from 'react';
import TrueFalseToIcon from '../../../components/UI/TrueFalseToIcon';
import api from '../../../services/api';

const PageSystemStores = (props) => {

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        async function fetchData(){
            await api.get(`/sys/stores`)
                .then( resp => {
                    const { data } = resp;
                    setList(data);
                }).catch( err => {
                    console.log('erro contact list', err);
                }).then( () => {
                    setLoading(false);
                })
        }
        if( loading ){
            fetchData();
        }
    }, [loading]);

    const MyList = () => {
        if( !list || !list.length ){
            return <p className="alert alert-warning">Nenhum item encontado.</p>
        }
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Ativo?</th>
                        <th>IUGU ID</th>
                        <th>Nome</th>
                        <th>Plataforma</th>
                        <th>Data Criação</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map( (value, key) => {
                            return  <tr key={key}>
                                        <td>{key}</td>
                                        <td><TrueFalseToIcon value={value.active} /></td>
                                        <td>{value.iuguId}</td>
                                        <td><a href={value.domain} target="_blank" rel="noreferrer">{value.name}</a></td>
                                        <td>{value.platform.name}</td>
                                        <td>{value.createdAt}</td>
                                    </tr>
                        })
                    }
                </tbody>
            </table>
        )
    }

    return (
        <div className="system-detail">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Lista de Instalações/Assinaturas</h1>
                        <p>Total de Regitro: <span className="badge bg-danger rounded-pill">{list.length}</span></p>
                    </div>
                    <div className="col-12">
                        {
                            loading
                            ? <p className="alert alert-warning">Aguarde...</p>
                            :
                                <MyList />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export { PageSystemStores }