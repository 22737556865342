import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import toast from 'react-hot-toast';

const SwitchStatus = (props) => {

    const [switchLoading, setSwitchLoading] = useState(false);
    const [switchValue, setSwitchValue] = useState();

    useEffect(() => {
        if( typeof props.value !== 'undefined' ){
            setSwitchValue(props.value)
        }
    }, [props.value]);

    const handleChange = async (e) => {
        setSwitchLoading(true);
        const value = e.target.checked;
        if( props.action ){
            let data = {};
            data[props.name] = value;
            await api.put(props.action, data)
                .then( resp => {
                    setSwitchValue(value);
                    if( props.successMsg ){
                        toast.success(props.successMsg);
                    }
                })
                .catch( err => {
                    console.log(err);
                    if( props.errorMsg ){
                        toast.error(props.errorMsg);
                    }
                })
                .then( () => {
                    setSwitchLoading(false);
                });
        }

        if( props.onChangeFunc ){
            setSwitchValue(value);
            await props.onChangeFunc(e);
            setSwitchLoading(false);
        }
    }

    return (
        <label className={`switch-input d-flex align-items-center ${props.className} ${props.size} ${props.offColorDanger&&!switchValue?'danger': ''}`} data-url={props.action}>
            <input type="checkbox" name={props.name} checked={switchValue} disabled={switchLoading} onChange={(e) => { handleChange(e) }}/>
            <i data-swon-text={props.onText} className="order-1 d-flex justify-content-between align-items-center" data-swoff-text={props.offText}></i>
            <span className="order-0">{props.label}</span>
        </label>

    )
}

export default SwitchStatus;
