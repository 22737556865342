import React, { useState } from 'react';
import { useParams } from 'react-router';
import { history } from '../../services/history';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { FormLabel, Button } from 'react-bootstrap';
import api from '../../services/api'

import ComponentAuthContent from '../../components/Auth/AuthContent';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageAuthResetPassword = () => {

    const { token } = useParams();
    const email = localStorage.getItem('reset-password-email') ?? '';
    
    const [isLoading, setLoading] = useState(false);
    
    const handleSubmit = async (values)=>{
        setLoading(true);
        await api.post('/auth/reset-password', values)
            .then(resp => {
                toast.success('Senha alterada');
                localStorage.removeItem('reset-password-email');
                history.push('/auth/login');
            }).catch( error => {
                toast.error('Erro ao alterar senha, tente novamente');
            }).then( () => {
                setLoading(false);
            });
    };

    const FormValidation = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required()
    })

    return (
        <ComponentAuthContent title="Alteração de senha" aditionalClass="reset-password-page">
            <Formik 
                initialValues={{
                    email: email,
                    password: '',
                    token: token
                }} 
                onSubmit={handleSubmit} 
                validationSchema={FormValidation}
            >
                {({ errors, touched }) => (
                    <Form className="form text-left">
                        <div className="form-group">
                            <FormLabel className="sr-only" htmlFor="login-email">E-mail</FormLabel>
                            <Field type="email" name="email" className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`} id="login-email" placeholder="E-mail" />
                            <ErrorMessage component="div" name="email" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <FormLabel className="sr-only" htmlFor="login-password">Senha</FormLabel>
                            <Field type="password" name="password" className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`} id="login-password" placeholder="Nova senha" />
                            <ErrorMessage component="div" name="password" className="invalid-feedback" />
                        </div>
                        <Field type="hidden" name="token" />
                        <Button variant="primary" size="lg" className="cta-submit btn-block" type="submit" disabled={isLoading}>
                            {isLoading ? <FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> : 'Alterar senha'}
                        </Button>
                    </Form>
                )}
            </Formik >
            <div className="auth-box--btn-group d-flex justify-content-center align-items-center flex-wrap text-center mt-3">
                <Link to="/auth/login" className="btn-link light btn-sm"><FontAwesomeIcon icon={['fas', 'lock']} /> Acessar minha conta</Link>
            </div>
        </ComponentAuthContent>
    )
}

export default PageAuthResetPassword;