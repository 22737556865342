import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ComponentAdminContent from '../../../components/Admin/AdminContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StoreCreateForm from './Forms/StoreCreateForm';
import { Card, Button, ButtonGroup } from 'react-bootstrap';
import SVGIcon from '../../../components/UI/SVGIcon';

const StoreCreateNsMessage = props => {

    const handleFormBackCreate = () => {
        props.setIsNuvemShop(false);

    }

    return (
        <Card className="my-store">
            <Card.Body>
                <Card.Title>Como cadastar sua Nuvem Shop!</Card.Title>

                <p>O cadastro de uma loja na Nuvem Shop é automatico, para cadastrar basta realizar a instação de um de nossos <b>aplicativos</b> diretamente da <a href="https://www.nuvemshop.com.br/loja-aplicativos-nuvem" target="_blank" rel="noreferrer">loja de aplicativos</a> da Nuvem Shop.</p>

                <Button variant="primary" onClick={handleFormBackCreate} >Escolher outra plataforma</Button>
            </Card.Body>
        </Card>
    )
}

const PageAdminStoreCreate = () => {

    const [isNumveShop, setIsNuvemShop] = useState(false);

    return (
        <ComponentAdminContent 
            title={<><SVGIcon icon={'store'} width="29" height="29" /> Cadastrar loja</>}
            subtitle={<>Cadastre sua loja e comece a usar!</>}
            titleExtra={
                <ButtonGroup>
                    <Link to="/admin/store" className="btn btn-default btn-sm"><FontAwesomeIcon icon={['fas', 'arrow-left']} className="me-1" /> VOLTAR</Link>
                </ButtonGroup>
            }
        >
            {
                ( isNumveShop )
                ? <StoreCreateNsMessage setIsNuvemShop={setIsNuvemShop} />
                : <StoreCreateForm setIsNuvemShop={setIsNuvemShop} />

            }
        </ComponentAdminContent>
    )
}

export default PageAdminStoreCreate;