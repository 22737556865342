import React from 'react';
import ComponentAdminContent from '../../components/Admin/AdminContent';
import SVGIcon from '../../components/UI/SVGIcon';

const PageAdminThemesList = () => {

    return (
        <ComponentAdminContent 
            title={<><SVGIcon icon="theme" width="29" height="29" /> Temas</>}
            subtitle={<></>}
        >
            <div className="themes-list">
                <h2>Ops :(</h2>
                <p>Ainda não temos temas para sua loja, mas não fique triste, estamos trabalhando duro e logo logo sua loja poderá usar nossos temas!</p>
            </div>
        </ComponentAdminContent>
    )
}

export default PageAdminThemesList;