import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import ComponentAdminContent from '../../components/Admin/AdminContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../services/api';
import { UserContext } from '../../context/UserContext';
import { Card } from 'react-bootstrap';
import { history } from '../../services/history';
import IuguSelectPaymentMethod from '../../components/Iugu/SelectPaymentMethods';

const PageAdminAppStoreInstall = () => {

    const { ActiveStore } = useContext(UserContext);
    const { appId } = useParams();
    const [appStore, setAppStore] = useState({});
    const [loading, setLoading] = useState(true);
    
    
    useEffect(() => {
        async function fetchData(){

            const values = {
                store: ActiveStore._id,
                app: appId,
                utmPlatform: localStorage.getItem(`utm_platform_${appId}`)??null
            }

            await api.post(`/app-store`, values)
                .then( resp => {
                    
                    const { data } = resp;
                    
                    if( data._id ){
                        setAppStore(data);

                        if( data.active ){
                            history.push(`/admin/apps/editor/${data._id}`);
                        }
                    }else{
                        toast.error('Erro ao buscar dados');
                    }
                    
                }).catch( err => {
                    try {
                        // const { error } = err.response.data;
                        // toast.error(error);
                        console.log('erro user', err);
                    } catch (err) {
                        console.log('erro try user', err);
                    }
                }).then( () => {
                    setLoading(false);
                })
        }
        if( ActiveStore._id ){
            fetchData();
        }
    }, [ActiveStore, appId]);

    return (
        <ComponentAdminContent 
            title={<><FontAwesomeIcon icon={['fas', 'lock']} fixedWidth className="text-primary" /> Assinar Aplicativo</>}
            subtitle={<>Ambinete 100% seguro!</>}
        >
            {
                ( loading )
                ? <p><FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> Aguarde...</p>
                : 
                    <div className="row">
                        <div className="col-md-6">
                            <Card className="store-checkout">
                                <Card.Header>
                                    <Card.Title className="text-danger">Atenção!</Card.Title>
                                    <p>Antes de realizar a assinatura confirme os dados da <b>loja</b> e do <b>aplicativo</b>.</p>
                                    <p>Nossos aplicativos são cobradas no sistema de recorrência mensal onde todos os aplicativos têm <b><u>{process.env.REACT_APP_TRIAL_DAYS} DIAS GRÁTIS</u></b> na primeira assinatura de cada aplicativo! Utilize o período de grátis para teste;</p>
                                    <p>A cobrança será realizada apenas no final do período de grátis caso não houver cancelamento; O cancelamento pode ser feito de forma rápida e fácil a qualquer momento via painel pelo usuário responsável da loja.</p>
                                    <p><i>Não guardamos nenhuma informação de cartão ou pagamento, nosso sistema é integrado com a <u>IUGU</u>; toda integração é feita através de tokenização, seguindo todos os cuidados de segurança necessário.</i></p>
                                </Card.Header>
                                <Card.Body>
                                    <IuguSelectPaymentMethod appStore={appStore} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6">
                            <Card className="store-info">
                                <Card.Header><Card.Title>Dados da Loja - {ActiveStore.name}</Card.Title></Card.Header>
                                <Card.Body>
                                    <dl className="row pl-3 pr-3">
                                        <dt className="col-3">Nome:</dt>
                                        <dd className="col-9">{ActiveStore.businessInfo.name}</dd>
                                        <dt className="col-3">E-mail:</dt>
                                        <dd className="col-9">{ActiveStore.businessInfo.email}</dd>
                                        <dt className="col-3">Documento:</dt>
                                        <dd className="col-9">{ActiveStore.businessInfo.document}</dd>
                                        <dt className="col-3">Telefone:</dt>
                                        <dd className="col-9">{ActiveStore.businessInfo.phone}</dd>
                                    </dl>
                                </Card.Body>
                            </Card>
                            <hr />
                            <Card className="app-info">
                                <Card.Header><Card.Title>Dados do App - {appStore.app.name.pt}</Card.Title></Card.Header>
                                <Card.Body>
                                    <dl className="row pl-3 pr-3">
                                        <dt className="col-3">Valor:</dt>
                                        <dd className="col-9">
                                            {
                                                appStore.app.specialPrice && appStore.app.specialPrice.pt
                                                ? (appStore.app.specialPrice.pt).toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})
                                                : (appStore.app.price.pt).toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})
                                            }
                                            {(appStore.app.paymentType === 'recurrent') ? "/mês" : ''}
                                        </dd>
                                        <dt className="col-3">Resumo:</dt>
                                        <dd className="col-9">{appStore.app.shortDescription.pt}</dd>
                                    </dl>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
            }
        </ComponentAdminContent>
    )
}

export default PageAdminAppStoreInstall;