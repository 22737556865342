import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../services/api';
import ComponentAdminContent from '../../../components/Admin/AdminContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, ButtonGroup } from 'react-bootstrap';
import ImageNoPicture from '../../../assets/img/no-picture.png';
import { Link } from 'react-router-dom';
import SVGIcon from '../../../components/UI/SVGIcon';

const PageAdminStoreView = () => {

    const { storeId } = useParams();
    const [Loading, setLoading] = useState(true);
    const [Store, setStore] = useState({});

    useEffect(() => {
        async function fetchData(){
            await api.get(`/stores/${storeId}`)
                .then( resp => {
                    
                    const { data } = resp;
                    try {
                        if( data._id ){
                            setStore(data);
                            return true;
                        }
                    } catch (error) {
                        
                    }

                }).catch( err => {
                    try {
                        // const { error } = err.response.data;
                        // toast.error(error);
                        console.log('erro user', err);
                    } catch (err) {
                        console.log('erro try user', err);
                    }
                }).then( () => {
                    setLoading(false);
                })
        }
        fetchData();
    }, [storeId]);

    if( Loading ){
        return (
            <p>Aguarde...</p>
        )
    }

    return (
        <ComponentAdminContent 
            title={<><SVGIcon icon={'store'} width="29" height="29" /> Minha Loja</>}
            subtitle={<>Tudo sobre a loja <b>{Store.name}</b></>}
            titleExtra={
                <ButtonGroup>
                    <Link to={`/admin/store`} className="btn btn-default btn-sm"><FontAwesomeIcon icon={['fas', 'arrow-left']} className="me-1" /> VER MAIS LOJAS</Link>
                </ButtonGroup>
            }
        >
            <Card className="my-store">
                
                <Card.Body>
                    <div className="row">

                        <div className="my-store--col-photo col-12 col-md-4 col-lg-3">
                            <div className="my-store--photo">
                                <img alt="{Store.name}" src={Store.photo?Store.photo:ImageNoPicture} className="img-fluid w-100" />

                                <br />
                                <div className="d-grid gap-2 mt-2">
                                    <Link to={`/admin/store/${Store._id}/subscriptions`} className="btn btn-primary"><FontAwesomeIcon icon={['fas', 'th-large']} className="me-1"/> MEUS APPS</Link>
                                    <Link to={`/admin/store`} className="btn btn-secondary"><FontAwesomeIcon icon={['fas', 'cog']} className="me-1" /> EDITAR LOJA</Link>
                                </div>
                            </div>
                        </div>
                        <div className="my-store--info col-12 col-md-8 col-lg-9">
                            <p>
                                {
                                    Store.active
                                    ? <span className="badge badge-success"><FontAwesomeIcon icon={['fas', 'check-circle']} fixedWidth className="me-1" /> Loja Ativa</span>
                                    : <span className="badge badge-danger"><FontAwesomeIcon icon={['fas', 'times-circle']} fixedWidth className="me-1" /> Loja Inativa</span>
                                }
                            </p>
                            <h2>{Store.name}</h2>
                            <p><a href="{Store.domain}" target="_Blank">{Store.domain}</a></p>
                            
                            <hr />

                            <h4 className="mb-0">Dados do responsável ou empresa</h4>
                            <p><small className="text-info"><FontAwesomeIcon icon={['fas', 'info-circle']} fixedWidth className="me-1" /> As informações serão utilizadas na emissão de NFSe.</small></p>
                            <div className="row mt-4">
                                <div className="col-12 col-md-6">
                                    <h5>Informações Basicas</h5>
                                    <dl className="row">
                                        <dt className="col-sm-4">Documento</dt>
                                        <dd className="col-sm-8">{Store.businessInfo.document? `${Store.businessInfo.type} - ${Store.businessInfo.document}` :'--'}</dd>
                                        <dt className="col-sm-4">Razão Socail</dt>
                                        <dd className="col-sm-8">{Store.businessInfo.name??'--'}</dd>
                                        <dt className="col-sm-4">E-mail</dt>
                                        <dd className="col-sm-8">{Store.businessInfo.email??'--'}</dd>
                                        <dt className="col-sm-4">Telefone</dt>
                                        <dd className="col-sm-8">{Store.businessInfo.phone??'--'}</dd>
                                    </dl>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h5>Endereço de Cobrança</h5>
                                    <dl className="row">
                                        <dt className="col-sm-4">Endereço</dt>
                                        <dd className="col-sm-8">{Store.businessAddress.address1?`${Store.businessAddress.address1} - ${Store.businessAddress.address2}`:'--'}</dd>
                                        <dt className="col-sm-4">Bairro</dt>
                                        <dd className="col-sm-8">{Store.businessAddress.address3??'--'}</dd>
                                        <dt className="col-sm-4">Complemento</dt>
                                        <dd className="col-sm-8">{Store.businessAddress.address4??'--'}</dd>
                                        <dt className="col-sm-4">Cidade/UF</dt>
                                        <dd className="col-sm-8">{Store.businessAddress.state? `${Store.businessAddress.city}/${Store.businessAddress.state}`:'--'}</dd>
                                        <dt className="col-sm-4">Pais</dt>
                                        <dd className="col-sm-8">{Store.businessAddress.country??'--'}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </Card.Body>
            </Card>
        </ComponentAdminContent>
    )
}

export default PageAdminStoreView;