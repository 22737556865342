import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../../context/UserContext';
import { Button, Modal } from 'react-bootstrap';
import api from '../../../services/api';
import toast from 'react-hot-toast';
import SVGIcon from '../../UI/SVGIcon';
import TableLIScriptInfo from '../../UI/TableLIScriptInfo';

const ComponentAdminNavContent = () => {
    const { pathname } = window.location;
    const { ActiveStore } = useContext(UserContext);
    const [showLiCode, setShowLiCode] = useState(false);
    const [installCodeLiHtml, setInstallCodeLiHtml] = useState('');
    const [installCodeLiCopied, setInstallCodeLiCopied] = useState(false);

    const LI_ID = process.env.REACT_APP_PLATFORM_LI_ID;

    useEffect(() => {
        let help_pathname = pathname.split('/')[2] ?? '';

        document.querySelector('#sidebar-nav .admin-sidebar--link').classList.remove('active');
        let item = document.querySelector(`#sidebar-nav .admin-sidebar--link[data-active-page="${help_pathname}"]`);

        if( item ){
            item.classList.add('active');
        }
    }, [pathname]);

    const InstallCodeLI = () => {
        if( !ActiveStore || typeof ActiveStore['platform'] === 'undefined' || ActiveStore['platform'] === null || ActiveStore['platform']._id !== LI_ID ){
            return '';
        }
        const handleClose = () => setShowLiCode(false);
        const handleCopyInstallCode = () => {
            try {
                navigator.clipboard.writeText(installCodeLiHtml)
                toast.success('Codigo Copiado');
                setInstallCodeLiCopied(true);
            } catch (error) {
                toast.error('Erro ao copiar codigo');
                setInstallCodeLiCopied(false);
            }
            return false;
        }
        const handleShow = async () => {

            await api.get(`/app-store/tokens/${ActiveStore._id}`)
                .then( (res) => {
                    const { data } = res;
                    let apps = [];

                    for( let x = 0; x < data.length; x++ ){
                        let app = data[x];
                        if( app.app.slug.indexOf('-li') >= 0 ){
                            apps.push(`{slug:"${app.app.slug}",page:"${app.app.liExecPage??''}",token:"${app.token}"}`)
                        }
                    }

                    // setInstallCodeLiHtml(`var HuamakeInit={loadApps:[${apps.join(',')}],baseStart:!1,appAwait:[],start:function(){for(var a=document.querySelector("body"),e=0;e<this.loadApps.length;e++){var t,o,p=this.loadApps[e];(""===p.page||0<=a.className.indexOf(p.page))&&(t="huamake-app-"+p.slug,document.getElementById(t)||((o=document.createElement("script")).id=t,o.setAttribute("data-token",p.token),o.src="https://cdn-local.huamake.com.br/loja_integrada/"+p.slug+"/js/app.js",document.body.appendChild(o)))}}};HuamakeInit.start();`);
                    setInstallCodeLiHtml(`"use strict";var HuamakeInit={loadApps:[${apps.join(',')}],baseStart:!1,appAwait:[],start:function(){for(var a=new URLSearchParams(window.location.search),e=Object.fromEntries(a.entries()),t=document.querySelector("body"),s=0;s<this.loadApps.length;s++){var o,n,c=this.loadApps[s];(""===c.page||0<=t.className.indexOf(c.page))&&(o="huamake-app-"+c.slug,document.getElementById(o)||((n=document.createElement("script")).id=o,n.setAttribute("data-token",c.token),e.debug&&"huamake-dev"===e.debug?n.src="https://cdn-local.huamake.com.br/loja_integrada/"+c.slug+"/js/app.js":n.src="https://cdn-local.huamake.com.br/loja_integrada/"+c.slug+"/js/app.min.js",document.body.appendChild(n)))}}};HuamakeInit.start();`);

                })
                .catch( (err) => {
                    console.log(err);
                    try {
                        toast.error(err.response.data.error);
                    } catch (error) {
                        toast.error('Erro ao criar código de instalação');
                    }
                });

            setShowLiCode(true);
            return false;
        }

        const appInstalled = localStorage.getItem('app-installed')??false;

        if( appInstalled ){
            localStorage.removeItem('app-installed');
            toast.success('Aplicativo instalado, novo script de gerado.');
            handleShow();
        }

        return (
            <>
                <Modal show={showLiCode} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Script de Instalação - LojaIntegrada/<b>Huamake</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Para que o app funcione em sua loja é preciso inserir este código de instalação.
                        <br />Crie um novo <u>Código HTML</u> no painel da <b>Loja Integrada</b> seguindo as configurações abaixo, caso o processo já tenha sido feito anteriormente não crie outro, altere o existente.
                        </p>
                        <TableLIScriptInfo description="HUAMAKE" local="RODAPÉ" page="TODAS AS PÁGINAS" type="JAVACRIPT" content={<textarea rel="textarea" className="form-control w-100" rows="5" readOnly={true} id="install-code-li" value={installCodeLiHtml}></textarea>} />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-center w-100">
                            <Button variant="primary" size="lg" onClick={handleCopyInstallCode}>
                                { 
                                    !installCodeLiCopied 
                                    ? <><FontAwesomeIcon icon={['fas', 'copy']} fixedWidth /> Copiar Script</>
                                    : <><FontAwesomeIcon icon={['fas', 'check']} fixedWidth /> Script Copiado</>
                                }
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Button variant="primary" className="m-auto" onClick={handleShow}><FontAwesomeIcon icon={['fas', 'code']} fixedWidth /> GERAR CÓDIGO</Button>
            </>
        );
    }
    
    return (
        <div id="sidebar-nav" className="admin-sidebar">
            <div className="admin-sidebar--scroll-box">
                <div className="admin-sidebar--scroll">
                    <nav className="admin-sidebar--nav">
                        <ul className="admin-sidebar--list nav not-ul-style">
                            {
                                ( ActiveStore && ActiveStore.name )
                                ? 
                                    <li>
                                        <Link to={`/admin/store/${ActiveStore._id}/view`} className="admin-sidebar--link-primary">
                                            <span>Loja Selecionada</span>
                                            <b>
                                                <FontAwesomeIcon icon={['fas', 'check-circle']} className="text-primary" /> <span>{ActiveStore.name}</span>
                                            </b>
                                        </Link>
                                    </li>
                                :   <li>
                                        <Link to={`/admin/store/`} className="admin-sidebar--link-alert">
                                            <span>Nenhuma loja selecionada</span>
                                            <b>
                                                <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="me-2" /> <span>Selecionar Loja</span>
                                            </b>
                                        </Link>
                                    </li>

                            }
                            <li>
                                <Link to="/admin" data-active-page="" className="admin-sidebar--link">
                                    {/* <FontAwesomeIcon icon={['fas', 'home']} fixedWidth /> <span>Dashboard</span> */}
                                    <SVGIcon icon="home" /> <span>Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/store" data-active-page="store" className="admin-sidebar--link">
                                    <SVGIcon icon="store" /> <span>Minhas Lojas</span>
                                </Link>
                            </li>
                            <li className={ActiveStore && ActiveStore.name ?'':'d-none'}>
                                <Link to="/admin/apps" data-active-page="apps" className="admin-sidebar--link">
                                    <SVGIcon icon="apps" width="16" height="16" />  <span>Aplicativos</span>
                                </Link>
                            </li>
                            <li className={ActiveStore && ActiveStore.name ?'d-none':'d-none'}>
                                <Link to="/admin/themes" className="admin-sidebar--link">
                                    <SVGIcon icon="theme" width="16" height="16" /> <span>Temas</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/help" data-active-page="help" className="admin-sidebar--link">
                                    <FontAwesomeIcon icon={['fas', 'question-circle']} fixedWidth className="text-primary" /> <span>Ajuda</span>
                                </Link>
                            </li>
                            <li><hr/></li>
                            
                            <InstallCodeLI />
                        </ul>
                    </nav>
                </div>
            </div>            
        </div>
    )
}

export default ComponentAdminNavContent;