import React, { useEffect, useState, useContext } from 'react';
import api from '../../services/api';
import { UserContext } from '../../context/UserContext';
import IuguPaymentMethodsForm from './PaymentMethodsForm';
import { ButtonGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { history } from '../../services/history';

const IuguSelectPaymentMethod = (props) => {

    const { ActiveStore } = useContext(UserContext)
    const [showForm, setShowForm] = useState(true);
    const [loading, setLoading] = useState(true);
    const [paymentsMethods, setPaymentsMethods] = useState([]);

    useEffect(() => {
        async function feachData(){
            await api.get(`/stores/${ActiveStore._id}/uigu/payment-methods`)
                .then( resp => {

                    setPaymentsMethods(resp.data)
                    setShowForm(!(resp.data.length))
                    setLoading(false);

                })
                .catch( err => {
                    console.log(err.response.data)
                    setLoading(false);
                });
        }

        if( loading && ActiveStore && ActiveStore.iuguId ){
            feachData();
        }
    }, [ActiveStore, loading]);
    
    const ShowPaymentSelect = () => {

        const RemovePaymentMethod = async (id) => {
            confirmAlert({
                title: 'Excluir cartão?',
                message: 'Tem certeza que deseja excluir este cartão?',
                buttons: [
                  {
                    label: 'SIM',
                    onClick: async () => {
                        await api.delete(`/stores/${ActiveStore._id}/uigu/payment-methods/${id}`)
                            .then( resp => {
                                if( resp.data.message ){
                                    setLoading(true);
                                    return toast.success('Cartão removido com sucesso')
                                }
                                return toast.error('Erro ao remover cartão')
                            })
                            .catch( err => {
                                console.log(err.response.data)
                                return toast.error('Erro ao remover cartão, tente novamente')
                            });
                    }
                  },
                  {
                    label: 'NÃO',
                    onClick: () => {}
                  }
                ]
            });
        }

        const ExecutePayment = async () => {
            confirmAlert({
                title: 'Assinar aplicativo',
                message: 'Tem certeza que deseja assinar este app?',
                buttons: [
                  {
                    label: 'SIM',
                    onClick: async () => {
                        await api.post(`/app-store/${props.appStore._id}/pay`)
                            .then( resp => {
                                console.log(resp.data)
                                if( resp.data.subscription ){
                                    toast.success('Assinatura Realizada');
                                    localStorage.setItem('app-installed', true);
                                    return history.push(`/admin/apps/editor/${props.appStore._id}`)
                                }
                                return toast.error('Erro ao assinar app');
                            })
                            .catch( err => {
                                console.error(err.response.data)
                                toast.error('Ocorreu um erro na tentativa de cobrança');
                            });
                    }
                  },
                  {
                    label: 'NÃO',
                    onClick: () => {}
                  }
                ]
            });
        }

        return (
            <div className="payments-methods-list">
                <div className="d-flex align-items-center justify-content-between pb-2">
                    <h4>Meu cartão</h4>
                    {/* <Button variant="secondary" size="sm" className="m-0" onClick={()=>{setShowForm(true)}}>Novo cartão</Button> */}
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Cartão</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        paymentsMethods.map( (value, key) => {
                            return (
                                <tr className="payments-methods-list--item" data-json={JSON.stringify(value)} key={key}>
                                    <td className="description">{value.description}</td>
                                    <td className="number">{value.data.display_number}</td>
                                    <td className="actions">
                                        <ButtonGroup>
                                            <Button variant="danger" onClick={()=>{RemovePaymentMethod(value.id)}}><FontAwesomeIcon icon={['fas', 'trash-alt']} /></Button>
                                            {/* <Button variant="success" onClick={()=>{ExecutePayment(value.id)}}><FontAwesomeIcon icon={['fas', 'check']} /></Button> */}
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="3">
                                <Button variant="success" onClick={ExecutePayment}>
                                    <FontAwesomeIcon icon={['fas', 'lock']} fixedWidth /> Assinar App
                                </Button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }

    return (
        <>
            {
                (loading)
                ? <div><p>Aguarde...</p></div>
                :
                    ( !showForm )
                        ? <ShowPaymentSelect />
                        : <IuguPaymentMethodsForm appStore={props.appStore} execReload={setLoading} />
            }
        </>
    )
}

export default IuguSelectPaymentMethod;