import { library } from '@fortawesome/fontawesome-svg-core';

import { 
  faLock,
  faPlusSquare,
  faCircleNotch,
  faLongArrowAltLeft,
  faSignOutAlt,
  faUserCircle,
  faEnvelope,
  faCog,
  faHome,
  faStore,
  faPalette,
  faRocket,
  faPlusCircle,
  faArrowLeft,
  faBlog,
  faExternalLinkAlt,
  faMagic,
  faTrashAlt,
  faCheck,
  faSearch,
  faGripLines,
  faEdit,
  faCode,
  faCopy,
  faMapMarkerAlt,
  faCheckCircle,
  faTimesCircle,
  faThLarge,
  faInfoCircle,
  faSave,
  faExclamationTriangle,
  faQuestionCircle,
  faLongArrowAltRight,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons'
// import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faInstagram, faMedapps, faPinterest, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

library.add(
  faLock,
  faPlusSquare,
  faCircleNotch,
  faLongArrowAltLeft,
  faSignOutAlt,
  faUserCircle,
  faEnvelope,
  faCog,
  faHome,
  faStore,
  faPalette,
  faRocket,
  faPlusCircle,
  faArrowLeft,
  faExternalLinkAlt,
  faMagic,
  faTrashAlt,
  faCheck,
  faSearch,
  faGripLines,
  faEdit,
  faCode,
  faCopy,
  faMapMarkerAlt,
  faCheckCircle,
  faTimesCircle,
  faThLarge,
  faInfoCircle,
  faSave,
  faExclamationTriangle,
  faQuestionCircle,
  faLongArrowAltRight,
  faAngleDown,
  
  faMedapps,
  faFacebook,
  faInstagram,
  faYoutube,
  faTwitter,
  faPinterest,
  faBlog,

);