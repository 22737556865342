import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { FormLabel, Button } from 'react-bootstrap';
import api from '../../services/api'

import ComponentAuthContent from '../../components/Auth/AuthContent';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageAuthForgotPassword = () => {

    const [isLoading, setLoading] = useState(false);
    const [resMessage, setResMessage] = useState(null);

    const handleSubmit = async (values)=>{
        setLoading(true);
        
        localStorage.setItem('reset-password-email', values.email);

        await api.post('/auth/forgot-password', values)
            .then(resp => {
                toast.success('E-mail de recuperação enviado');
                setResMessage({type: 'success', msg: 'Acesse seu e-mail e siga as instruções de recuperação'});
            }).catch( error => {
                console.log(error);
                setResMessage({type: 'danger', msg: 'Não conseguimos enviar o e-mail de recuperação, tente novamente!'});
            }).then( () => {
                setLoading(false);
            });
    };

    const FormValidation = yup.object().shape({
        email: yup.string().email().required()
    })

    return (
        <ComponentAuthContent title="Informe seu e-mail" aditionalClass="forgot-password-page">
            <div className="form-alert-message">
                {
                    resMessage !== null 
                        ? <p className={`alert alert-${resMessage.type}`}>{resMessage.msg}</p>
                        : ''
                }
            </div>
            <Formik 
                initialValues={{
                    email: '',
                }} 
                onSubmit={handleSubmit} 
                validationSchema={FormValidation}
            >
                {({ errors, touched }) => (
                    <Form className="form text-left">
                        <div className="form-group">
                            <FormLabel className="sr-only" htmlFor="login-email">E-mail</FormLabel>
                            <Field type="email" name="email" className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`} id="login-email" placeholder="name@example.com" />
                            <ErrorMessage component="div" name="email" className="invalid-feedback" />
                        </div>
                        <Button variant="primary" size="lg" className="cta-submit btn-block" type="submit" disabled={isLoading}>
                            {isLoading ? <FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> : 'Enviar'}
                        </Button>
                    </Form>
                )}
            </Formik >
            <div className="auth-box--btn-group d-flex justify-content-center align-items-center flex-wrap text-center mt-3">
                <Link to="/auth/login" className="btn-link light btn-sm"><FontAwesomeIcon icon={['fas', 'lock']} /> Acessar minha conta</Link>
            </div>
        </ComponentAuthContent>
    )
}

export default PageAuthForgotPassword;