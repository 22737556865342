import React, { useEffect, useState, useContext } from 'react';
import api from '../../../services/api';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { history } from '../../../services/history';
import { UserContext } from '../../../context/UserContext';

const PageAdminAppNsInstall = (props) => {

    const { appSlug } = useParams();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const [loading, setLoading] = useState(false);
    const { ActiveStore, setActiveStoreId } = useContext(UserContext);
    

    useEffect(() => {
        async function fetchData(){
            await api.post(`/app-store/ns/install`, { appSlug, storeId: ActiveStore._id, code })
                .then( resp => {
                    
                    const { data } = resp;
                    
                    if( data._id ){
                        setActiveStoreId(data.store);
                        toast.success('App instalado com sucesso');
                        return history.push(`/admin/apps/editor/${data._id}`)
                    }

                    toast.error('Erro ao instalar app');
                    return history.push('/admin/apps')
                    
                }).catch( err => {
                    try {
                        const { error } = err.response.data;
                        toast.error(`Erro ao instalar app: ${error}`);
                    } catch (err) {
                        toast.error(`Erro ao instalar app, erro não identificado`);
                    }
                    return history.push('/admin/apps')
                }).then( () => {
                    setLoading(false);
                })
        }
        if( !loading && code && appSlug && ActiveStore ){
            fetchData();
        }
    }, [code, appSlug, ActiveStore, setActiveStoreId, loading]);


    return (<p>OK</p>)
}

export default PageAdminAppNsInstall;