import i18next from "i18next";
import t_auth from "../translations/pages/t_auth.json";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'pt',
    resources: {
        pt: {
            ...t_auth.pt
        },
        en: {
            ...t_auth.en
        },
        es: {
            ...t_auth.es
        },
    },
});

export default i18next