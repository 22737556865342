import React, { useEffect, useState } from 'react';
import TrueFalseToIcon from '../../../components/UI/TrueFalseToIcon';
import api from '../../../services/api';

const PageSystemAppStores = (props) => {

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    const search = window.location.search;
    // const params = new URLSearchParams(search);

    useEffect(() => {
        async function fetchData(){

            await api.get(`/sys/installations${search}`)
                .then( resp => {
                    const { data } = resp;
                    setList(data);
                }).catch( err => {
                    console.log('erro contact list', err);
                }).then( () => {
                    setLoading(false);
                })
        }
        if( loading ){
            fetchData();
        }
    }, [loading]);

    const MyList = () => {
        if( !list || !list.length ){
            return <p className="alert alert-warning">Nenhum item encontado.</p>
        }
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>UTM</th>
                        <th>Ativo?</th>
                        <th>Em uso?</th>
                        <th>App</th>
                        <th>Responsavel</th>
                        <th>Loja</th>
                        <th>Token/Assinatura</th>
                        <th>Data Criação</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map( (value, key) => {
                            let utmPlatform = value.utmPlatform??'';
                            let domain = ( value.store ) ? value.store.domain : 'No Store';
                            let storeName = ( value.store ) ? value.store.name : 'No Store';
                            let storeBusinessName = ( value.store && value.store.businessInfo ) ? value.store.businessInfo.name : 'No Store';
                            let storeBusinessEmail = ( value.store && value.store.businessInfo ) ? value.store.businessInfo.email : 'No Store';
                            let appName = ( value.app && value.app.name ) ? value.app.name.pt : 'Not App';
                            let newDate = new Date(value.createdAt);
                            let valueDate = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()} ${newDate.getHours()}:${newDate.getMinutes()}`;
                            
                            return  <tr key={key} className="align-middle">
                                        <td>{key}</td>
                                        <td>{utmPlatform}</td>
                                        <td><TrueFalseToIcon value={value.active} /></td>
                                        <td><TrueFalseToIcon value={value.inUse} /></td>
                                        <td>{appName}</td>
                                        <td>
                                            {storeBusinessName}
                                            <br />
                                            {storeBusinessEmail}
                                        </td>
                                        <td><a href={domain} target="_blank" rel="noreferrer">{storeName}</a></td>
                                        <td>
                                            {value.token??'--'}
                                            <br />
                                            <b className="text-success">{value.subscriptionId??'--'}</b>
                                        </td>
                                        <td>{valueDate}</td>
                                    </tr>
                        })
                    }
                </tbody>
            </table>
        )
    }

    return (
        <div className="system-detail">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Lista de Instalações/Assinaturas</h1>
                        <p>Total de Regitro: <span className="badge bg-danger rounded-pill">{list.length}</span></p>
                    </div>
                    <div className="col-12">
                        {
                            loading
                            ? <p className="alert alert-warning">Aguarde...</p>
                            :
                                <MyList />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export { PageSystemAppStores }