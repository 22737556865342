import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import ComponentAdminContent from '../../components/Admin/AdminContent';
import SVGIcon from '../../components/UI/SVGIcon';

const PageAdminDashboard = () => {

    return (
        <ComponentAdminContent 
            title={<div className="d-flex align-items-center"><SVGIcon icon={'home'} className="me-2" width="30" height="27" /> <span>Dashboard</span></div>}
            subtitle=""
        >
            <div className="help-init">
                <h2>Estamos quase la :)</h2>
                <p>Nosso projeto esta em fase BETA, mas você ja pode começar a vender mais com nossos apps!</p>

                <p className="alert alert-danger"><FontAwesomeIcon icon={['fab', 'youtube']} fixedWidth className="mr-1" /> Veja nossa playlist de videos tutoriais <a href="https://www.youtube.com/watch?v=Ruybv2KX4Jo&amp;list=PLURIgSDI4D76e7f00WS4xpbDCP36iAGKD" target="_blank" rel="noreferrer" className="text-danger fw-bold">clicando aqui</a>.</p>

                <p>Siga os passos abaixo para começar utilizar nossos apps:</p>
                <hr />
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="help-init-border d-flex align-items-center">
                            <div className="help-init-number color-1">1</div>
                            <div className="help-init-text">
                                <h3>CRIAR LOJA</h3>
                                <p><Link to="/admin/store/create">Clique aqui</Link> para cadastrar sua loja.<br /><b>ATENÇÃO:</b> informe os dados de cobrança corretamente!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="help-init-border d-flex align-items-center">
                            <div className="help-init-number color-2">2</div>
                            <div className="help-init-text">
                                <h3>ATIVE SUA LOJA</h3>
                                <p><Link to="/admin/store">Clique aqui</Link> para selecionar uma loja.<br /><b>ATENÇÃO:</b> antes de qualquer ação, certifique-se da loja selecionada!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="help-init-border d-flex align-items-center">
                            <div className="help-init-number color-3">3</div>
                            <div className="help-init-text">
                                <h3>INSTALAÇÃO</h3>
                                <p><Link to="/admin/apps">Clique aqui</Link> para instalar um app.<br /><b>ATENÇÃO:</b> antes de instalar/assinar qualquer app, valide as informações!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ComponentAdminContent>
    )
}

export default PageAdminDashboard;