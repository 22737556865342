import axios from 'axios'
import { history } from './history'

const Logout = () => {
    localStorage.removeItem('token_type')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('token')
    localStorage.removeItem('store')
    history.push('/auth/login');
    return true;
}

const _axios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL
})

_axios.interceptors.request.use(
    config => {
        
        config.headers["Access-Control-Allow-Origin"] = '*';

        const type = localStorage.getItem('token_type')
        const token = localStorage.getItem('token')

        if( token !== undefined && token !== null && token !== '' && token !== 'undefined' && token ){
            try {
                config.headers['Authorization'] = `${type} ${JSON.parse(token)}`;
            } catch (error) {
                config.headers['Authorization'] = undefined;
            }
            // history.push('/admin');
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

_axios.interceptors.response.use(
    (response) => {
        return response
    }, 
    (error) => {
        const { url } = error.response.config;
        try {
            if ( ( error.response.status === 401 ) && url.indexOf('/auth/') === -1 ) {
                return Promise.reject(Logout());
            }
        } catch (error) {
            
        }

        return Promise.reject(error);
    }
);

export default _axios