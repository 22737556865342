import React from 'react'

const Icon = ({icon}) => {
    let returnIcon = <></>;
    switch (icon) {
        case 'home':
            returnIcon = <path d="M-941.981,734.816v-3.25l-.451.451-.451.451-.8-.8-.8-.8,4.246-4.246,4.246-4.246,4.246,4.246,4.246,4.246-.8.8-.8.8-.451-.451-.451-.451v6.5h-11.993Zm9.745-2.249v-3.25l-1.874-1.874-1.874-1.874-1.874,1.874-1.874,1.874v6.5h7.5Z" transform="translate(944.475 -722.383)" fill="#00d0d1"/>
            break;
        case 'store':
            returnIcon = <path d="M21.948,11.756a5.807,5.807,0,0,0-.826-2.984l-1.45-2.421A.725.725,0,0,0,19.048,6H8.9a.725.725,0,0,0-.622.352L6.828,8.771A5.808,5.808,0,0,0,6,11.756v.3a2.669,2.669,0,0,0,1.45,2.379v5.335a2.175,2.175,0,0,0,2.175,2.175h8.7A2.175,2.175,0,0,0,20.5,19.773V14.438a2.669,2.669,0,0,0,1.45-2.379ZM12.524,20.5V17.6h2.9v2.9Zm5.8,0h-1.45V16.874a.725.725,0,0,0-.725-.725H11.8a.725.725,0,0,0-.725.725V20.5H9.624a.725.725,0,0,1-.725-.725V14.664a2.537,2.537,0,0,0,1.339-.622c.038-.034.076-.071.111-.107a2.537,2.537,0,0,0,3.625,0,2.537,2.537,0,0,0,3.625,0c.034.036.072.072.111.107a2.537,2.537,0,0,0,1.339.622v5.109A.725.725,0,0,1,18.323,20.5Zm2.175-8.44a1.157,1.157,0,0,1-.973,1.185,1.087,1.087,0,0,1-1.2-1.082.725.725,0,0,0-1.45,0,1.087,1.087,0,1,1-2.175,0,.725.725,0,0,0-1.45,0,1.087,1.087,0,1,1-2.175,0,.725.725,0,0,0-1.45,0,1.087,1.087,0,0,1-1.2,1.087,1.159,1.159,0,0,1-.973-1.185v-.308a4.35,4.35,0,0,1,.62-2.239L9.311,7.45h9.326l1.241,2.068a4.349,4.349,0,0,1,.62,2.238Z" transform="translate(-6 -6)" fill="#00d0d1"/>
            break;
        case 'apps':
            returnIcon = <path d="M61.4,55.212a1.656,1.656,0,0,1,1.651,1.651V61.4A1.656,1.656,0,0,1,61.4,63.054h-4.54A1.656,1.656,0,0,1,55.212,61.4v-4.54a1.656,1.656,0,0,1,1.651-1.651Zm.413,6.191v-4.54a.418.418,0,0,0-.413-.413h-4.54a.418.418,0,0,0-.413.413V61.4a.418.418,0,0,0,.413.413H61.4A.418.418,0,0,0,61.815,61.4ZM61.4,46.545A1.656,1.656,0,0,1,63.054,48.2v4.54A1.656,1.656,0,0,1,61.4,54.387h-4.54a1.656,1.656,0,0,1-1.651-1.651V48.2a1.656,1.656,0,0,1,1.651-1.651Zm.413,6.191V48.2a.418.418,0,0,0-.413-.413h-4.54a.418.418,0,0,0-.413.413v4.54a.418.418,0,0,0,.413.413H61.4A.418.418,0,0,0,61.815,52.736ZM48.2,46.545h4.54A1.656,1.656,0,0,1,54.387,48.2v4.54a1.656,1.656,0,0,1-1.651,1.651H48.2a1.656,1.656,0,0,1-1.651-1.651V48.2A1.656,1.656,0,0,1,48.2,46.545Zm4.952,6.191V48.2a.418.418,0,0,0-.413-.413H48.2a.418.418,0,0,0-.413.413v4.54a.418.418,0,0,0,.413.413h4.54a.418.418,0,0,0,.413-.413Zm-.413,2.476a1.656,1.656,0,0,1,1.651,1.651V61.4a1.656,1.656,0,0,1-1.651,1.651H48.2A1.656,1.656,0,0,1,46.545,61.4v-4.54A1.656,1.656,0,0,1,48.2,55.212Zm.413,6.191v-4.54a.418.418,0,0,0-.413-.413H48.2a.418.418,0,0,0-.413.413V61.4a.418.418,0,0,0,.413.413h4.54a.418.418,0,0,0,.413-.413Z" transform="translate(-46.545 -46.545)" fill="#00d0d1"/>          
            break;
        case 'theme':
            returnIcon = <g transform="translate(98 -39.765)"><path d="M19.8,5.341,17.3,2.834a1.165,1.165,0,0,0-1.634,0l-4.679,4.66L7.7,4.189a2.468,2.468,0,1,0-3.49,3.49l3.286,3.286-4.66,4.679a1.166,1.166,0,0,0,0,1.634l2.506,2.506h0a1.166,1.166,0,0,0,1.634,0l4.66-4.66L15.4,18.894a1.053,1.053,0,0,0,.668.334l2.117.371a.632.632,0,0,0,.186.019,1.178,1.178,0,0,0,.873-.371,1.29,1.29,0,0,0,.334-1.058l-.353-2.1a1.273,1.273,0,0,0-.334-.668l-3.75-3.787,4.66-4.66A1.166,1.166,0,0,0,19.8,5.341Zm-11.548,4.9-2.1-2.117L8.144,6.139l2.1,2.1ZM4.542,5.935a1.388,1.388,0,0,1,.408-1,1.5,1.5,0,0,1,1-.408,1.388,1.388,0,0,1,1,.408l.446.446L5.4,7.383l-.446-.446A1.468,1.468,0,0,1,4.542,5.935ZM18.5,18.485a.123.123,0,0,1-.13.037l-2.191-.39-.334-.316,1.987-1.987.334.334a.142.142,0,0,1,.037.093l.334,2.1A.123.123,0,0,1,18.5,18.485Zm-1.43-3.4-1.987,1.987L12.4,14.382,14.382,12.4Zm1.968-8.856L6.232,19.042a.09.09,0,0,1-.13,0L3.6,16.536a.09.09,0,0,1,0-.13l.91-.91.91.91a.5.5,0,0,0,.371.149.529.529,0,0,0,.371-.91l-.91-.91,1.281-1.281.91.91a.5.5,0,0,0,.371.149.529.529,0,0,0,.371-.91l-.91-.91,1.281-1.281L10.019,12.8a.5.5,0,0,0,.371.149.529.529,0,0,0,.371-.91L9.351,10.632l1.281-1.281.91.91a.5.5,0,0,0,.371.149.529.529,0,0,0,.371-.91l-.91-.91,1.281-1.281.91.91a.5.5,0,0,0,.371.149.529.529,0,0,0,.371-.91l-.91-.91,1.281-1.281,1.467,1.411a.5.5,0,0,0,.371.149.529.529,0,0,0,.371-.91L15.478,4.505l.91-.91h.13L19.024,6.1A.08.08,0,0,1,19.042,6.232Z" transform="translate(-100.5 37.265)" fill="#00d0d1"/></g>          
            break;
        default:
            returnIcon = <></>
            break;
    }
    return returnIcon;
}
const SVGIcon = (props) => {
    const { className, width, height } = props;

    return (
        <svg className={`${className??''}`} xmlns="http://www.w3.org/2000/svg" width={width??"16"} height={height??"16"} viewBox={`0 0 20 20`}>
            <Icon icon={props.icon} />
        </svg>
    )
}

export default SVGIcon;