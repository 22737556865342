import React, {useContext} from 'react'
import { Router, Route, Switch, Redirect } from "react-router";
import { history } from './services/history'
import { Context } from './context/AuthContext';
import toast from 'react-hot-toast';

import PageAuthLogin from './pages/Auth/Login';
import PageAdminDashboard from './pages/Admin/Dashboard';
import PageAuthForgotPassword from './pages/Auth/ForgotPassword';
import PageAuthResetPassword from './pages/Auth/ResetPassword';
import PageAuthRegister from './pages/Auth/Register';
import PageAuthConfirmEmail from './pages/Auth/ConfirmEmail';
import PageAdminStoreView from './pages/Admin/Store/StoreView';
import PageAdminStoreCreate from './pages/Admin/Store/StoreCreate';
import PageAdminStoreList from './pages/Admin/Store/StoreList';
import PageAdminAppList from './pages/Admin/Apps';
import PageAdminThemesList from './pages/Admin/Themes';
import PageAdminAppStoreInstall from './pages/Admin/AppStoreInstall';
import PageAdminEditor from './pages/Admin/Editor';
import PageAdminAppNsInstall from './pages/Admin/NuvemShop/AppNsInstall';
import PageError404 from './pages/Errors/Error404';
import PageAdminStoreSubscriptions from './pages/Admin/Store/StoreSubscriptions';
import PageAdminInstafeedAuth from './pages/Admin/Apps/InstafeedAuth';
import { PageAdminHelpTopics, PageAdminHelpTopicsView, PageAdminHelpContactForm } from './pages/Admin/Help/HelpTopics';
import PageAppDetail from './pages/Apps/Detail/AppDetail';
import { PageSystemContactList } from './pages/System/Contact/PageSystemContact';
import { PageSystemAppStores } from './pages/System/AppStores/PageSystemAppStores';
import Loader from './components/UI/Loader';
import { PageSystemStores } from './pages/System/Stores/PageSystemStores';

function CustomRoute({ isPrivate, isInstallNS, ...rest }){
    const { path } = rest

    console.log(path)

    const { loading, authenticated } = useContext(Context)
    
    if( loading ){
        return (
            <Loader type="page" />
        )
    }

    if( path === '/auth/login' && authenticated ){
        return <Redirect to="/admin" />
    }

    if( isPrivate && !authenticated ){
        if( isInstallNS ){
            toast.error('Crie uma conta, ou faça login antes de instalar um app');
        }
        return <Redirect to="/auth/login" />
    }else if( authenticated && ['/auth/'].indexOf(path) >= 0 ){
        return <Redirect to="/admin" />
    }
    return <Route {...rest} />
}

const Routes = () => (
    <Router history={history}>
        <Switch>
            {/* AUTH PAGES */}
            <CustomRoute component={PageAppDetail} exact path="/detail/:appSlug" />

            <CustomRoute component={PageAuthRegister} exact path="/auth/register" />
            <CustomRoute component={PageAuthConfirmEmail} exact path="/auth/confirm-email/:token" />
            <CustomRoute component={PageAuthLogin} exact path="/auth/login" />
            <CustomRoute component={PageAuthForgotPassword} exact path="/auth/forgot-password" />
            <CustomRoute component={PageAuthResetPassword} exact path="/auth/reset-password/:token" />
            
            {/* ADMIN PAGES */}
            <CustomRoute component={PageAdminDashboard} isPrivate={true} exact path="/admin" />
            <CustomRoute component={PageAdminDashboard} isPrivate={true} exact path="/" />
            <CustomRoute component={PageAdminStoreList} isPrivate={true} exact path="/admin/store" />
            <CustomRoute component={PageAdminStoreCreate} isPrivate={true} exact path="/admin/store/create" />
            <CustomRoute component={PageAdminStoreView} isPrivate={true} exact path="/admin/store/:storeId/view/" />
            <CustomRoute component={PageAdminStoreSubscriptions} isPrivate={true} exact path="/admin/store/:storeId/subscriptions" />
            
            <CustomRoute component={PageAdminHelpTopics} isPrivate={true} exact path="/admin/help" />
            <CustomRoute component={PageAdminHelpContactForm} isPrivate={true} exact path="/admin/help/contact-form" />
            <CustomRoute component={PageAdminHelpTopicsView} isPrivate={true} exact path="/admin/help/:helpTopicId" />
            
            <CustomRoute component={PageAdminInstafeedAuth} isPrivate={true} exact path="/admin/apps/instafeed/auth" />
            
            <CustomRoute component={PageAdminAppList} isPrivate={true} exact path="/admin/apps" />
            <CustomRoute component={PageAdminThemesList} isPrivate={true} exact path="/admin/themes" />
            <CustomRoute component={PageAdminAppStoreInstall} isPrivate={true} exact path="/admin/apps/:appId/install" />
            <CustomRoute component={PageAdminEditor} isPrivate={true} exact path="/admin/apps/editor/:appStoreId" />
            <CustomRoute component={PageAdminAppNsInstall} isPrivate={true} isInstallNS={true} exact path="/admin/apps/ns-install/:appSlug" />
            
            <CustomRoute component={PageSystemContactList} isPrivate={true} exact path="/sys/contacts" />
            <CustomRoute component={PageSystemAppStores} isPrivate={true} exact path="/sys/installations" />
            <CustomRoute component={PageSystemStores} isPrivate={true} exact path="/sys/stores" />
            
            <CustomRoute path='*' component={PageError404} />
        </Switch>
    </Router>
)

export default Routes