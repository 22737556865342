import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { InputField } from '../Formik/Field';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import api from '../../services/api';

const IuguPaymentMethodsForm = (props) => {
    
    const { appStore } = props;
    const [loadingCheckout, setloadingCheckout] = useState(true);
    const [btnIsLoading, setBtnIsLoading] = useState(false);
    const [cardBrand, setCardBrand] = useState(false);
    
    useEffect( ()=>{
        if( !document.getElementById('IuguJS') ){
            const script = document.createElement("script");
            script.id = 'IuguJS';
            script.src = process.env.REACT_APP_IUGU_URL;
            script.async = true;
            document.body.appendChild(script);
        
            script.onload = script.onreadystatechange = function () {
                if (
                    (!this.readyState ||
                        this.readyState === "loaded" ||
                        this.readyState === "complete")
                ) {
                    var testMode = false;
                    if( process.env.REACT_APP_IUGU_TEST === 'true' ){
                        testMode = true
                    }
                    window.Iugu.setAccountID(process.env.REACT_APP_IUGU_ID);
                    window.Iugu.setTestMode(testMode);
                    window.Iugu.setup();
                    setloadingCheckout(false);
                }
            };
        }
        return true;
    }, [])
    
    async function handleSubmit(values){
        if(!btnIsLoading){
            
            setBtnIsLoading(true);
            var tokenResponseHandler = async function(data) {
          
                if (data.errors) {
                    toast.error("Erro salvando cartão: " + JSON.stringify(data.errors));
                    setBtnIsLoading(false);
                    return false;
                }
                
                const reqValues = {
                    description: values.description, 
                    token: data.id, 
                    cardCustomerName: values.full_name 
                }
                await api.post(`/stores/${appStore.store}/uigu/payment-methods`, reqValues)
                    .then( resp => {
                        
                        const { data } = resp;
                        if( !data.id ){
                            return toast.error('Erro ao cadastrar cartão');
                        }

                        if( props.execReload ){
                            props.execReload(true);
                        }else{
                            window.location.reload(false);
                        }
                        
                    }).catch( err => {
                        try {
                            const { error } = err.response.data;
                            toast.error(error);
                            console.log('erro user', err.response.data);
                        } catch (err) {
                            toast.error('Erro na tentativa de cadastrar cartão - try');
                        }
                    }).then( () => {
                        setBtnIsLoading(false);
                    })
            }
            
            window.Iugu.createPaymentToken(document.getElementById('paymentForm'), tokenResponseHandler);
            
            return false;
        }
    }

    const FormValidation = yup.object().shape({
        description: yup.string().max(30).required(),
        number: yup.string().test('validateCreditCardNumber', 'Cartão Inválido', (value) => {
            try {
                return ( value && value.trim() !== '' && window.Iugu.utils.validateCreditCardNumber(value) )
            } catch (error) {
                
            }
        }),
        expiration: yup.string().test('validateExpirationString', 'Data Inválida', (value) => {
            try {
                return ( value && value.trim() !== '' && window.Iugu.utils.validateExpirationString(value) )
            } catch (error) {
                
            }
        }),
        verification_value: yup.string().test('validateCVV', 'CCV Inválido', (value) => {
            try {
                return ( value && value.trim() !== '' && window.Iugu.utils.validateCVV(value, cardBrand) )
            } catch (error) {
                
            }
        })
    })

    return (
        <div className="mp-checkout-recorrencia">
            { 
                loadingCheckout
                ? <p><FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> Aguarde...</p>
                : 
                <Formik 
                    initialValues={{
                        // description: '',
                        // full_name: 'Leonam Bernini',
                        // number: '4111111111111111',
                        // expiration: '07/22',
                        // verification_value: '207',
                        description: '',
                        full_name: '',
                        number: '',
                        expiration: '',
                        verification_value: '',
                    }} 
                    onSubmit={handleSubmit} 
                    validationSchema={FormValidation}
                    enableReinitialize={true}
                >
                    {() => (
                        <Form className="form text-left" id="paymentForm">
                            <legend>CARTÃO PARA PAGAMENTO</legend>
                            
                            <InputField name="description" type="text" label="Identificador do cartão" placeholder="ex: Cartão virtual" />
                            <InputField data-iugu="full_name" name="full_name" type="text" label="Titular do Cartão" />
                            <div className="row">
                                <div className="col-md-6">
                                    <InputField autoComplete="off" data-iugu="number" name="number" type="text" label="Número do Cartão" onBlur={(e) => setCardBrand(window.Iugu.utils.getBrandByCreditCardNumber(e.target.value))} />
                                </div>
                                <div className="col-md-3">
                                    <InputField autoComplete="off" data-iugu="expiration" name="expiration" type="text" label="MM/AA" mask="99/99" />
                                </div>
                                <div className="col-md-3">
                                    <InputField autoComplete="off" data-iugu="verification_value" name="verification_value" type="text" label="CVV" />
                                </div>
                            </div>

                            <div className="mt-3">
                                <Button variant="primary" size="lg" className="cta-submit" type="submit" disabled={btnIsLoading}>
                                    {
                                        btnIsLoading 
                                            ? <FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> 
                                            : <><FontAwesomeIcon icon={['fas', 'lock']} fixedWidth /> SALVAR</>}
                                </Button>
                                <hr />
                                <p><small>A transação é toda criptografada, os dados são transformados em um token e enviados para <b>IUGU</b> onde é realizado todo processamento.</small></p>
                            </div>
                        </Form>
                    )}
            </Formik >
            }
        </div>
    )
};

export default IuguPaymentMethodsForm;
