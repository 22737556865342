import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { Context } from '../../context/AuthContext';
import { FormLabel, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ComponentAuthContent from '../../components/Auth/AuthContent';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageAuthLogin = () => {
    
    const [ t ] = useTranslation('auth');
    const [isLoading, setLoading] = useState(false);
    const { handleLogin } = useContext(Context);

    const handleSubmit = async (values) => {
        setLoading(true);
        await handleLogin(values)
        setLoading(false);
    }
    
    const LoginValidation = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().min(6).required()
    })

    return (
        <ComponentAuthContent title={t('login.title')} aditionalClass="login-page">
            <Formik 
                initialValues={{
                    email: '',
                    password: ''
                }} 
                onSubmit={handleSubmit} 
                validationSchema={LoginValidation}
            >
                {({ errors, touched }) => (
                    <Form className="form text-left">
                        <div className="form-group">
                            <FormLabel className="sr-only" htmlFor="login-email">E-mail</FormLabel>
                            <Field type="email" name="email" className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`} id="login-email" placeholder="E-mail" />
                            <ErrorMessage component="div" name="email" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <FormLabel className="sr-only" htmlFor="login-password">Senha</FormLabel>
                            <Field type="password" name="password" className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`} id="login-password" placeholder="Senha" />
                            <ErrorMessage component="div" name="password" className="invalid-feedback" />
                        </div>
                        <Button variant="primary" size="lg" className="cta-submit w-100" type="submit">
                            {isLoading ? <FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> : 'Entrar'}
                        </Button>
                    </Form>
                )}
            </Formik >
            <div className="auth-box--btn-group d-flex justify-content-between align-items-center flex-wrap text-center mt-3">
                <Link to="/auth/register" className="btn-link light btn-sm">Cadastre-se</Link>
                <span className="divider"></span>
                <Link to="/auth/forgot-password" className="btn-link light btn-sm"><FontAwesomeIcon icon={['fas', 'lock']} /> Esqueceu a senha?</Link>
            </div>
        </ComponentAuthContent>
    )
}

export default PageAuthLogin;