import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Image } from 'react-bootstrap';
import Brand from '../../UI/Brand';
import { Context } from '../../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ImageNoPicture from '../../../assets/img/no-picture.png';

import { UserContext } from '../../../context/UserContext';

const ComponentAdminHeaderContent = () => {

    const { handleLogout } = useContext(Context);
    const { User } = useContext(UserContext);

    function toggleFullWidth(){
        document.querySelector('body').classList.toggle('layout-fullwidth'); 
    }

    return (
        <header className="admake-header sticky-top fixed-top container-fluid">
            <div className="d-flex justify-content-between align-items-center">
                <div className="admake-header--brand d-flex align-items-center">
                    <div className="brand">
                        <Link to="/admin"><Brand /></Link>
                    </div>
                    <div className="container-fluid">
                        <div className="navbar-btn">
                            <Button type="button" variant="light" className="btn-toggle-fullwidth" onClick={toggleFullWidth}><FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} /></Button>
                        </div>
                    </div>
                </div>

                <div className="admake-header--options">
                    <div className="d-flex align-items-center justify-content-end">
                        <Dropdown>
                            <Dropdown.Toggle variant="basic" id="dropdown-basic">
                                <Image src={ ( typeof User !== 'undefined' && 'photo' in User ) ? User.photo : ImageNoPicture } roundedCircle />
                                <span>{ User.name }</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/* <Dropdown.Item href="#"><FontAwesomeIcon icon={['fas', 'user-circle']} fixedWidth />Minha conta</Dropdown.Item> */}
                                {/* <Dropdown.Item href="#"><FontAwesomeIcon icon={['fas', 'envelope']} fixedWidth />Mensagens</Dropdown.Item> */}
                                {/* <Dropdown.Item href="#"><FontAwesomeIcon icon={['fas', 'cog']} fixedWidth />Configurações</Dropdown.Item> */}
                                <Dropdown.Item onClick={handleLogout}><FontAwesomeIcon icon={['fas', 'sign-out-alt']} fixedWidth />Sair</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default ComponentAdminHeaderContent;