import React, { useState } from 'react';
import { Field, useField } from 'formik';
import { FormLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import uniqueId from 'lodash/uniqueId'
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreateLabelHelper = ({id, labelHelper}) => {
    if( labelHelper && labelHelper !== '' ){
        return (
            <OverlayTrigger placement="top" 
                overlay={
                    <Tooltip id={`tooltip-${id}`}>
                        {labelHelper}
                    </Tooltip>
                }
            >
                <span><FontAwesomeIcon icon={['fas', 'question-circle']} className="ms-1 text-primary"/></span>
            </OverlayTrigger>
        )
    }else{
        return "";
    }
}

export const InputField = ({ boxClassName, mask, label, labelHelper, ...props }) => {
    
    const nodeRef = React.useRef<HTMLInputElement>(null);

    const [ id ] = useState(uniqueId('form-input-'))
    props = { id, ...props };
    const [field, meta ] = useField(props);

    if( props.type === 'hidden' ){
        return (
            <input {...field} {...props} id={id} />
        )
    }

    return (
        <div className={`${(props.resetStyle)?'form-group-reset':'form-group'} ${boxClassName??''}`}>
            { 
                ( label && label.length ) 
                ? 
                    <FormLabel htmlFor={id}>
                        { label }
                        <CreateLabelHelper labelHelper={labelHelper} id={id} />
                    </FormLabel> 
                : <></> }
            {
                ( !mask )
                ? <Field className={`form-control ${meta.error && meta.touched ? ' is-invalid' : ''}`} {...field} {...props} id={id} />
                : <InputMask inputRef={nodeRef} mask={mask} className={`form-control ${meta.error && meta.touched ? ' is-invalid' : ''}`} onChange={e => {
                    const value = e.target.value || '';
                    meta.setFieldValue(field.name, value); }} {...field} {...props} />
            }
            {/* <ErrorMessage component="div" name={field.name} className="invalid-feedback" /> */}
        </div>
    )
}

export const SelectField = ({ label, labelHelper, ...props }) => {
    
    const [ id ] = useState(uniqueId('form-select-'))
    props = { id, ...props };
    const [field, meta ] = useField(props);

    return (
        <div className="form-group">
            <FormLabel htmlFor={id}>
                { label }
                <CreateLabelHelper labelHelper={labelHelper} id={id} />
            </FormLabel>
            <Field as="select" className={`form-control ${meta.error && meta.touched ? ' is-invalid' : ''}`} {...field} {...props} id={id} onChange={e => {
                if( 'onChange' in props ){
                    props.onChange(e);
                }
                field.onChange(e); 
            }} >
                {props.children}
            </Field>
            {/* <ErrorMessage component="div" name={name} className="invalid-feedback" /> */}
        </div>
    )
}