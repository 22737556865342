import React, { createContext, useEffect, useState } from 'react'
import api from '../services/api'
import { history } from '../services/history';
import toast from 'react-hot-toast';
import { useCookies } from 'react-cookie';

const Context = createContext({});

function AuthProvider(props){

    const [cookies, removeCookie] = useCookies(['active_store']);
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() =>{
        const type = localStorage.getItem('token_type')
        const token = localStorage.getItem('token')

        if( token !== undefined && token !== null && token !== '' && token !== 'undefined' && token ){
            try {
                api.defaults.headers.Authorization = `${type} ${JSON.parse(token)}`
                setAuthenticated(true);
            } catch (error) {
                setAuthenticated(false);
            }
            // history.push('/admin');
        }else{
            setAuthenticated(false);
        }

        setLoading(false)

    }, []);

    async function handleLogin(values) {
        setLoading(true)

        return await api.post('/auth/authenticate', values)
            .then(resp => {
                const { user, token } = resp.data;
                
                localStorage.setItem('token_type', 'Bearer')
                localStorage.setItem('token', JSON.stringify(token))
                localStorage.setItem('user', user._id)
                api.defaults.headers.Authorization = `Bearer ${token}`

                setAuthenticated(true);
                window.location.reload();
                history.push('/admin');
            }).catch( err => {
                const { error } = err.response.data;
                toast.error(error);
            }).then( () => {
                setLoading(false)
            })
    }

    function resetAuthLogout(){
        console.log(cookies.active_store)
        removeCookie('active_store');
        localStorage.clear();
        sessionStorage.clear();
        
    }

    function handleLogout(){

        setAuthenticated(false);
        resetAuthLogout();
        api.defaults.headers.Authorization = undefined;
        window.location.reload();
        history.push('/auth/login');
    }
    
    function cleanAuthLocalStorage(){
        resetAuthLogout();
    }

    return (
        <Context.Provider value={{ loading, authenticated, handleLogin, handleLogout, cleanAuthLocalStorage }}>
            {props.children}
        </Context.Provider>
    );
}

export { Context, AuthProvider }