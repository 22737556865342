import React from 'react';

import ComponentAuthContent from '../../components/Auth/AuthContent';
import ComponentFormsRegister from '../../components/Forms/ComponentFormsRegister';

const PageAuthRegister = () => {
    
    return (
        <ComponentAuthContent title="Crie sua conta, é simples e rapido" aditionalClass="register-page">
            <ComponentFormsRegister />
        </ComponentAuthContent>
    )
}

export default PageAuthRegister;