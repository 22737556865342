import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

const ComponentsFormikFieldOptionsPlatforms = (props) => {

    const [options, setOptions] = useState([{value: "", name: 'Buscando plataformas...'}]);

    useEffect(() => {
        async function fetchData(){
            await api.get('/platforms/select')
                .then( resp => {
                    
                    const { data } = resp;
                    const helpOptions = [];
                    if( data.length ){
                        helpOptions.push({value: '', name: 'Selecione'});

                        for( let x = 0; x < data.length; x++ ){
                            helpOptions.push({
                                value: data[x]._id,
                                name: data[x].name,
                            })
                        }
                        setOptions(helpOptions);
                    }else{
                        setOptions([{value: "", name: 'Sem plataformas'}])    
                    }

                }).catch( err => {
                    setOptions([{value: "", name: 'Sem plataformas'}])
                }).then( () => {
                    
                })
        }
        fetchData();
    }, []);

    return (
        <>
            {options.map((e, key) => {
                return <option key={key} value={e.value}>{e.name}</option>;
            })}
        </>
    )
}

export default ComponentsFormikFieldOptionsPlatforms;