import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import { FormLabel, Button } from 'react-bootstrap';
import api from '../../services/api'
import toast from 'react-hot-toast';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ComponentFormsRegister = (props) => {
    
    const [isLoading, setLoading] = useState(false);
    const [resMessage, setResMessage] = useState(null);

    const handleSubmit = async (values)=>{
        setLoading(true);
        
        await api.post('/auth/register', values)
            .then(resp => {
                toast.success('Registro criado');
                setResMessage({type: 'success', msg: 'Registro criado, enviamos um e-mail de confirmação.'});
            }).catch( err => {
                const { error } = err.response.data;
                toast.error(error);
                setResMessage({type: 'danger', msg: `Não conseguimos criar sua conta, tente novamente! - ${error}`});
            }).then( () => {
                setLoading(false);
            });
    };
    
    const FormValidation = yup.object().shape({
        name: yup.string().min(3).required(),
        email: yup.string().email().required(),
        password: yup.string().min(8).required(),
        whatsapp: yup.string().min(10).required()
    })

    return (
        <>
            {
                resMessage != null
                    ? <p className={`text-center alert alert-${resMessage.type}`}>{resMessage.msg}</p>
                    :
                    <Formik 
                        initialValues={{
                            name: '',
                            email: '',
                            password: '',
                            whatsapp: ''
                        }} 
                        onSubmit={handleSubmit} 
                        validationSchema={FormValidation}
                    >
                        {({ errors, touched }) => (
                            <Form className="form text-left">
                                <div className="form-group">
                                    <FormLabel className="sr-only" htmlFor="register-name">Nome</FormLabel>
                                    <Field type="text" name="name" className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`} id="register-name" placeholder="Nome completo" />
                                    <ErrorMessage component="div" name="name" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <FormLabel className="sr-only" htmlFor="register-email">E-mail</FormLabel>
                                    <Field type="email" name="email" className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`} id="register-email" placeholder="E-mail" />
                                    <ErrorMessage component="div" name="email" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <FormLabel className="sr-only" htmlFor="register-password">Senha</FormLabel>
                                    <Field type="password" name="password" className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`} id="register-password" placeholder="Senha" />
                                    <ErrorMessage component="div" name="password" className="invalid-feedback" />
                                </div>
                                <div className="form-group">
                                    <FormLabel className="sr-only" htmlFor="register-whatsapp">Senha</FormLabel>
                                    <Field type="whatsapp" name="whatsapp" className={`form-control ${errors.whatsapp && touched.whatsapp ? 'is-invalid' : ''}`} id="register-whatsapp" placeholder="Whatsapp" />
                                    <ErrorMessage component="div" name="whatsapp" className="invalid-feedback" />
                                </div>
                                <Button variant="primary" size="lg" className="cta-submit btn-block" type="submit" disabled={isLoading}>
                                    {isLoading ? <FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> : 'Registrar'}
                                </Button>
                            </Form>
                        )}
                    </Formik >
            }
            
            {
                ( props.hideLoginButton )
                    ?   <></>
                    :   <div className="auth-box--btn-group d-flex justify-content-center align-items-center flex-wrap text-center mt-3">
                            <p>Ja tenho conta! <Link to="/auth/login" className="btn-link light btn-sm">Fazer login</Link>.</p>
                        </div>
            }
        </>
    )
}

export default ComponentFormsRegister;