import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loader = ({type, text}) => {

    if( type && type === 'page' ){
        return (
            <div className="loader-page">
                <div className="loader-page-content">
                    <FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> 
                    <p>{text??'Aguarde...'}</p>
                </div>
            </div>
        )
    }else{
        return (
            <div className="loader-text">
                <div className="loader-text-content">
                    <p className="d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={['fas', 'circle-notch']} spin />  {text??'Aguarde...'}</p>
                </div>
            </div>
        )
    }
}

export default Loader;
