import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const TrueFalseToIcon = ({value}) => {
    if ( value ){
        return <FontAwesomeIcon icon={['fas', 'check-circle']} fixedWidth className="text-success" />
    }else{
        return <FontAwesomeIcon icon={['fas', 'times-circle']} fixedWidth className="text-danger" />
    }
}

export default TrueFalseToIcon;