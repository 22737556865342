import React from 'react';
import brandImg from '../../assets/img/huamake.png';

const Brand = (props) => {
    const { _width, _height, _className } = props;
    return (
        <div className={_className?_className:'brand'}>
            <img src={brandImg} alt="" width={_width?_width:'148'} height={_height?_height:'27'} />
            {/* <svg width={_width?_width:'148px'} height={_height?_height:'30px'} viewBox="0 0 311 63" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-11.000000, -21.000000)">
                        <g  transform="translate(11.000000, 21.000000)">
                            <path d="M39.6052419,39.0917587 C39.4584084,33.83108 40.8371572,28.6419165 43.5715645,24.1638063 L36.9973848,4.82463891 C36.6228683,3.73557133 35.6092506,3.00412428 34.4688541,3 L21.0428521,3 C19.9050363,3.00262813 18.8921919,3.72950126 18.5143215,4.81461342 L0.150247827,58.4008496 C-0.136511039,59.2277794 -0.00801577152,60.1440212 0.494808352,60.8577553 C0.997632476,61.5714894 1.81208975,61.9937224 2.67877848,61.9899745 L12.0690472,61.9899745 C13.2202624,61.9933585 14.2437302,61.2496041 14.6074937,60.1452846 L17.2351824,52.1248938 L38.1178709,52.1248938 L40.7356438,60.1452846 C41.1011734,61.2496984 42.1222303,61.9957334 43.2740903,62 L52,62 C49.7926298,60.7813579 47.7992291,59.2028707 46.1000951,57.3281223 C41.8098562,52.5894081 39.6449051,46.5106202 39.6052419,39.0917587 Z M20.8247044,41.2071368 L27.7062741,20.2638912 L34.577928,41.2071368 L20.8247044,41.2071368 Z" id="Shape" fill="#D84F48" fillRule="nonzero"></path>
                            <path d="M49.277931,56.2450618 C45.0926437,51.7573016 43,45.9945331 43,38.9567563 C43,31.9189795 45.0926437,26.1427544 49.277931,21.6280812 C53.1441358,17.249919 58.7451436,14.7718511 64.6070539,14.8459788 C70.6276372,14.8459788 75.2531247,17.06631 78.4835164,21.5069722 L78.4835164,2.714897 C78.480804,1.99404878 78.7678338,1.30194683 79.28089,0.792226603 C79.7939461,0.282506372 80.4905775,-0.00265719785 81.2161434,1.86632004e-05 L90.2673352,1.86632004e-05 C90.9929011,-0.00265719785 91.6895325,0.282506372 92.2025886,0.792226603 C92.7156448,1.30194683 93.0026746,1.99404878 92.9999812,2.714897 L92.9999812,59.6966674 C92.9999812,60.4157683 92.7117425,61.1052703 92.1989814,61.6128036 C91.6862203,62.1203369 90.9911372,62.4041177 90.2673352,62.4014344 L81.2161434,62.4014344 C80.4923414,62.4041177 79.7972583,62.1203369 79.2844972,61.6128036 C78.7717361,61.1052703 78.4835114,60.4157683 78.4835164,59.6966674 L78.4835164,55.5789625 C75.3909171,60.3024668 70.06439,63.106751 64.3937262,62.9968869 C58.6057884,63.0409994 53.0861938,60.5755428 49.277931,56.2450618 L49.277931,56.2450618 Z M75.3547094,47.4343842 C77.447353,45.4159014 78.4936749,42.5496558 78.4936749,38.8356473 C78.4936749,35.1216389 77.4778284,32.2755781 75.3547094,30.2772801 C73.4551115,28.3483446 70.8533909,27.2611057 68.1371204,27.2611057 C65.4208498,27.2611057 62.8191292,28.3483446 60.9195313,30.2772801 C58.8268877,32.295763 57.7805658,35.1620086 57.7805658,38.876017 C57.7805658,42.5900254 58.8268877,45.4461786 60.9195313,47.4444766 C62.8198899,49.3706709 65.4172332,50.4610041 68.1320411,50.4722055 C70.8546735,50.4747929 73.4615433,49.378357 75.3547094,47.4343842 L75.3547094,47.4343842 Z" id="Shape" fill="#FCC860" fillRule="nonzero"></path>
                            <path d="M172.915222,24.482061 C172.158497,22.9314494 171.166738,21.5046891 169.974377,20.2513147 C166.617247,16.7523012 162.071693,15.0027945 156.337715,15.0027945 C153.070454,14.9604766 149.854436,15.8096837 147.041822,17.4574256 C144.287861,19.0724868 141.963836,21.3168409 140.263777,24.0031086 C138.843965,21.2168341 136.635657,18.9006742 133.90873,17.3376875 C131.075606,15.7522584 127.866156,14.9461258 124.612837,15.0027945 C118.100005,15.0027945 113.35638,17.4474474 110.381963,22.3367533 L110.381963,18.1558978 C110.384674,17.4414698 110.098726,16.7556722 109.587881,16.2514299 C109.077036,15.7471875 108.383775,15.4664321 107.662688,15.4717687 L98.6991513,15.4717687 C97.9815484,15.4717637 97.2934828,15.7548732 96.7870068,16.2585339 C96.2805308,16.7621947 95.9973412,17.4449416 96,18.1558978 L96,59.3158709 C96,60.7943865 97.2068339,61.9945037 98.6991513,62 L107.662688,62 C109.155005,61.9945037 110.361831,60.7943865 110.361821,59.3158709 L110.361821,36.7352602 C110.361821,33.7285034 111.164175,31.4002625 112.768883,29.7505375 C116.557872,26.4878191 122.193556,26.4878191 125.982546,29.7505375 C127.684826,31.6717333 128.547051,34.1842182 128.379537,36.7352602 L128.379537,59.3158709 C128.379527,60.7943865 129.586352,61.9945037 131.078669,62 L139.981778,62 C141.474095,61.9945037 142.680921,60.7943865 142.68091,59.3158709 L142.68091,36.7352602 C142.513396,34.1842182 143.375621,31.6717333 145.077901,29.7505375 C148.866891,26.4878191 154.502575,26.4878191 158.291564,29.7505375 C159.896273,31.3869583 160.695269,33.7151992 160.688555,36.7352602 L160.688555,59.3158709 C160.694078,60.796002 161.903801,61.9945281 163.397759,62 L172.290796,62 C173.787048,62 175,60.7982744 175,59.3158709 L175,56.7714362 C170.971444,52.1548671 168.937023,46.3143085 168.896738,39.2497604 C168.768118,34.045943 170.163869,28.9166393 172.915222,24.482061 Z" id="Path" fill="#9ED17D"></path>
                            <path d="M178.308406,55.4167369 C174.102802,51.0235696 172,45.3991334 172,38.5434285 C172,31.6877235 174.09603,26.0468704 178.28809,21.6208693 C182.150316,17.3482488 187.74811,14.9294504 193.607054,15.0015679 C199.63441,15.0015679 204.259897,17.1686011 207.483517,21.5026675 L207.483517,18.1339159 C207.483511,17.4320767 207.771736,16.7591258 208.284497,16.2637756 C208.797258,15.7684254 209.492341,15.4914566 210.216143,15.4940755 L219.277494,15.4940755 C220.781072,15.4940755 221.999981,16.6759723 221.999981,18.1339159 L221.999981,58.7756383 C222.002663,59.4774726 221.717025,60.1514597 221.20617,60.6486582 C220.695315,61.1458568 220.001301,61.4253338 219.277494,61.4253289 L210.216143,61.4253289 C208.732463,61.3931236 207.548717,60.2146373 207.554626,58.7756383 L207.554626,54.7567768 C204.466109,59.3712232 199.1369,62.1095702 193.464836,61.9966376 C187.664374,62.0536936 182.127831,59.6500995 178.308406,55.4167369 L178.308406,55.4167369 Z M204.364868,46.8569558 C206.491373,44.8540918 207.554626,42.0533655 207.554626,38.4547771 C207.554626,34.8561886 206.491373,32.0587458 204.364868,30.0624486 C202.457054,28.1946539 199.858408,27.1438213 197.147279,27.1438213 C194.43615,27.1438213 191.837504,28.1946539 189.92969,30.0624486 C187.837046,32.0324787 186.790724,34.833205 186.790724,38.4646272 C186.790724,42.0960495 187.837046,44.8803588 189.92969,46.8175552 C191.837504,48.6853498 194.43615,49.7361824 197.147279,49.7361824 C199.858408,49.7361824 202.457054,48.6853498 204.364868,46.8175552 L204.364868,46.8569558 Z" id="Shape" fill="#0EB3C4" fillRule="nonzero"></path>
                            <path d="M236.628387,62 L227.706607,62 C226.98877,62 226.300334,61.7147461 225.792747,61.2070882 C225.28516,60.6994304 225,60.0108986 225,59.2929619 L225,2.69695752 C225.005522,1.20584871 226.215695,0 227.706607,0 L236.628387,0 C238.117668,0 239.32497,1.20746901 239.32497,2.69695752 L239.32497,34.7797235 L253.008373,16.3421589 C253.515307,15.6542324 254.319205,15.2485054 255.173659,15.2493397 L267.293245,15.2493397 C268.343265,15.2453916 269.300089,15.8514036 269.745426,16.8024475 C270.190762,17.7534915 270.043615,18.8765938 269.368311,19.6807718 L253.06852,39.1008711 L269.167821,57.5183839 C269.858326,58.3136814 270.024879,59.4373565 269.594762,60.3988007 C269.164646,61.3602449 268.215966,61.9848651 267.162927,62 L255.374149,62 C254.553264,62.0051678 253.774702,61.6361299 253.258985,60.9973589 L239.32497,43.3117341 L239.32497,59.2929619 C239.327635,60.0099751 239.044708,60.6985352 238.538712,61.2064834 C238.032715,61.7144315 237.345305,62 236.628387,62 L236.628387,62 Z" id="Path" fill="#71549B"></path>
                            <path d="M287.229626,61.9913603 C280.429744,61.9913603 274.856508,59.837632 270.509917,55.5301755 C266.163326,51.222719 263.993364,45.50352 264.000015,38.3725783 C264.000015,31.2416366 266.15666,25.561835 270.469918,21.3331734 C274.783176,17.1045119 280.369745,14.9934642 287.229626,15.0000152 C294.08284,15.0000152 299.749408,17.0848131 304.229329,21.2543785 C308.709251,25.4239438 310.965879,31.2120885 310.999626,38.6188125 C311.007299,40.1057213 310.896963,41.5909645 310.669217,43.060877 L278.619776,43.060877 C279.172738,46.9247977 282.469787,49.8354364 286.42964,49.9554338 C287.804331,50.0182118 289.17933,49.8380818 290.489569,49.423568 C291.211261,49.1756541 291.886858,48.8129938 292.489534,48.349987 C292.78995,48.0847843 293.073797,47.8019189 293.339519,47.5029415 C293.844163,46.932124 294.571126,46.5991726 295.339484,46.5869503 L305.599306,46.5869503 C306.557862,46.5786889 307.448995,47.0716513 307.940673,47.8821628 C308.432352,48.6926744 308.450845,49.6991934 307.989264,50.5266971 C306.45824,53.4216593 304.24261,55.9113653 301.529377,57.7856806 C297.340139,60.6537623 292.329602,62.1274044 287.229626,61.9913603 L287.229626,61.9913603 Z M293.329519,29.0747759 C291.657075,27.7261042 289.550604,27.0100615 287.389623,27.0556556 C285.28047,26.9931287 283.224522,27.7138367 281.629723,29.0747759 C280.06357,30.4239912 279.026867,32.2710412 278.699774,34.2949404 L296.16947,34.2949404 C295.995143,32.24332 294.967292,30.3540089 293.329519,29.0747759 L293.329519,29.0747759 Z" id="Shape" fill="#DD5F8C" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </svg> */}
        </div>
    )
};

export default Brand;