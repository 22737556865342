import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ComponentAdminContent from '../../../components/Admin/AdminContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import { UserContext } from '../../../context/UserContext';
import SVGIcon from '../../../components/UI/SVGIcon';

const PageAdminStoreList = () => {

    const { Stores, setActiveStoreId, ActiveStore } = useContext(UserContext);

    return (
        <ComponentAdminContent 
            title={<><SVGIcon icon={'store'} width="29" height="29" />  <span>Minhas Lojas</span></>}
            subtitle={<></>}
            titleExtra={
                <ButtonGroup>
                    <Link to="/admin/store/create" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={['fas', 'plus-circle']} /> NOVA LOJA</Link>
                </ButtonGroup>
            }
        >
            <div className="store-list p-2">
                <div className="row">
                    {
                        Stores
                        ? Stores.map( (value, key) => {
                            return (<div key={key} className="col-12 col-md-6 mb-4">
                                <Card className={`store-list--item ${ ( value.active ) ? ( ActiveStore !== null && value._id === ActiveStore._id ) ? 'store-list--item-active':'' : 'store-list--item-disabled'}`}>
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <h3>
                                            <Link to={`/admin/store/${value._id}/view`} className="m-0 btn-link">
                                                { value.name }
                                            </Link>
                                        </h3>
                                        <span className={`badge badge-${value.active?'success':'danger'}`}>
                                            { value.active ? 'Loja Ativa' : 'Loja Inativa' }
                                        </span>
                                    </Card.Header>
                                    <Card.Body>
                                        <dl className="row">
                                            <dt className="col-sm-3">Idioma:</dt>
                                            <dd className="col-sm-9">{value.language}</dd>
                                            <dt className="col-sm-3">Dominio:</dt>
                                            <dd className="col-sm-9">{value.domain} <a href={value.domain} rel="noreferrer" target="_blank"><FontAwesomeIcon icon={['fas', 'external-link-alt']} /></a></dd>
                                            <dt className="col-sm-3">Plataforma:</dt>
                                            <dd className="col-sm-9">{value.platform.name}</dd>
                                        </dl>
                                    </Card.Body>
                                    <Card.Footer>
                                        <ButtonGroup>
                                            {
                                                value.active 
                                                ? ActiveStore !== null && value._id === ActiveStore._id
                                                    ? <div className="flag-store-selected"><FontAwesomeIcon icon={['fas', 'check-circle']} fixedWidth /> Loja Selecionada</div>
                                                    : <Button variant="primary" onClick={ () => { setActiveStoreId(value._id) } }>Selecionar Loja</Button>
                                                : <div className="flag-store-disabled"><FontAwesomeIcon icon={['fas', 'times-circle']} fixedWidth /> Loja Inativa</div>
                                            }
                                        </ButtonGroup>
                                    </Card.Footer>
                                </Card>
                            </div>)
                        })
                        : <div>Sem loja</div>
                    }
                </div>
            </div>
        </ComponentAdminContent>
    )
}

export default PageAdminStoreList;