import React, { Component } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { AuthProvider } from './context/AuthContext';
import {I18nextProvider} from "react-i18next";
import i18next from './config/i18Config';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { UserProvider } from './context/UserContext';

class App extends Component {
    render() {
        return (
            <CookiesProvider>
                <BrowserRouter>
                    <I18nextProvider i18n={i18next}>
                        <AuthProvider>
                            <UserProvider>
                                    <Routes />
                            </UserProvider>
                        </AuthProvider>
                    </I18nextProvider>
                </BrowserRouter>
            </CookiesProvider>
        )
    }
}

export default App;