import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import api from '../../../services/api';

const PageSystemContactList = (props) => {

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        async function fetchData(){
            await api.get(`/sys/contacts`)
                .then( resp => {
                    const { data } = resp;
                    setList(data);
                }).catch( err => {
                    console.log('erro contact list', err);
                }).then( () => {
                    setLoading(false);
                })
        }
        if( loading ){
            fetchData();
        }
    }, [loading]);

    const handleDelete = async (id) => {
        await api.delete(`/sys/contacts/${id}`)
            .then( resp => {
                toast.success('Removido');
            }).catch( err => {
                console.log('erro contact list', err);
            }).then( () => {
                setLoading(true);
            })
        return false;
    }

    const MyList = () => {
        if( !list || !list.length ){
            return <p className="alert alert-warning">Nenhum item encontado.</p>
        }
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Status</th>
                        <th>Loja</th>
                        <th>App</th>
                        <th>Usuario</th>
                        <th>Nome</th>
                        <th>Whatsapp</th>
                        <th>Assunto</th>
                        <th>Mensagem</th>
                        <th>Data Criação</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.map( (value, key) => {
                            let newDate = new Date(value.createdAt);
                            return  <tr key={key}>
                                        <td>{key}</td>
                                        <td>{value.status}</td>
                                        <td>{(value.store)?value.store.name:'--'}</td>
                                        <td>{(value.app)?value.app.name.pt:'--'}</td>
                                        <td>{(value.user)?<>{value.user.name}<br/><a href={`mailto:${value.user.email}`} target="_blank" rel="noreferrer">{value.user.email}</a></>:'--'}</td>
                                        <td>{value.name}</td>
                                        <td><a href={`https://api.whatsapp.com/send?phone=55${value.whatsapp.replace('-', '').replace('(', '').replace(')', '').replace(' ', '')}`} target="_blank" rel="noreferrer">{value.whatsapp}</a></td>
                                        <td>{value.subject}</td>
                                        <td>{value.message}</td>
                                        <td>{`${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()} ${newDate.getHours()}:${newDate.getMinutes    ()}`}</td>
                                        <td><Button onClick={() => { handleDelete(value._id) }} variant="danger"><FontAwesomeIcon icon={['fas', 'trash-alt']} /></Button></td>
                                    </tr>
                        })
                    }
                </tbody>
            </table>
        )
    }

    return (
        <div className="system-detail">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h1>Chamados de Ajuda</h1>
                        <p>Total de Regitro: <span className="badge bg-danger rounded-pill">{list.length}</span></p>
                    </div>
                    <div className="col-12">
                        {
                            loading
                            ? <p className="alert alert-warning">Aguarde...</p>
                            :
                                <MyList />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export { PageSystemContactList }