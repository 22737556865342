import React, { useContext, useState, useEffect } from 'react';
import ComponentAdminContent from '../../components/Admin/AdminContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import api from '../../services/api';
import { history } from '../../services/history';
import { UserContext } from '../../context/UserContext';

import ImageNoPicture from '../../assets/img/no-picture.png';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import SVGIcon from '../../components/UI/SVGIcon';

const PageAdminAppList = () => {

    const { ActiveStore } = useContext(UserContext);
    const [loading, setLoading] = useState(true)
    const [apps, setApps] = useState([])

    useEffect(() => {
        async function fetchData(){

            setLoading(false);

            if( !ActiveStore._id ){
                toast.error('Selecione uma Loja!');
                history.push('/admin/store');
            }else{
                await api.get(`/apps/store/${ActiveStore._id}`)
                    .then( resp => {
    
                        const { data } = resp;
                        // console.log(data)
                        setApps(data);
                        
                    }).catch( err => {
                        toast.error('Nenhum app para sua plataforma')
                        console.log('erro list app', err);
                    }).then( () => {
                        setLoading(false);
                    })
            }
        }
        if( loading && ActiveStore ){
            fetchData();
        }
    }, [loading, ActiveStore]);

    const AppCta = (props) => {
        const { app } = props;

        if( app.appInstaled ){
            return (
                <Link to={`/admin/apps/editor/${app.appStoreId}`} className="btn btn-primary btn-sm d-block"><FontAwesomeIcon icon={['fas', 'cog']} fixedWidth /> Configurar App</Link>
            )
        }

        if( app.platform !== null && app.platform._id === process.env.REACT_APP_PLATFORM_NS_ID ){
            return (
                <a href={app.externalInstallUrl.replace(/{store_domain}/g, ActiveStore.domain).replace(/{app_ref_id}/g, app.externalId)} className="btn btn-success btn-sm d-block"><FontAwesomeIcon icon={['fas', 'plus-circle']} fixedWidth />Instalar App</a> 
            )
        }
            
        return (
            <Link to={`/admin/apps/${app._id}/install`} className="btn btn-success btn-sm d-block"><FontAwesomeIcon icon={['fas', 'plus-circle']} fixedWidth /> Instalar App</Link>
        )
    }

    const AppList = (props) => {

        if( !apps.length ){
            return (
                <div className="col-12"><p>Nenhum aplicativo para plataforma</p></div>
            )
        }
        return (
            <>
                {
                    apps.map( (value, key) => {
                        return (
                            <div key={key} className="col-md-4 col-lg-3 mb-4 apps-list--item">
                                <Card className="apps-list--card">
                                    <Card.Img variant="top" src={value.photo.pt?value.photo.pt:ImageNoPicture} />
                                    <Card.Body>
                                        <Card.Title>{value.name.pt}</Card.Title>
                                        <div className="apps-list--short-description">{value.shortDescription.pt}</div>
                                        <span className="apps-list--prices d-flex align-items-center">
                                            { 
                                                value.paymentType === 'free'
                                                ? <span className="apps-list--free">Grátis</span>
                                                : value.specialPrice
                                                    ? 
                                                        <>
                                                            <span className="apps-list--old-price">{(value.price.pt).toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})}</span>
                                                            <span className="apps-list--price">{(value.specialPrice.pt).toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})}<small>/mês</small></span>
                                                        </>
                                                    : <span className="apps-list--price">{(value.price.pt).toLocaleString("pt-BR", { style: "currency" , currency:"BRL"})}<small>/mês</small></span>
                                            }
                                        </span>
                                        <AppCta app={value} />
                                    </Card.Body>
                                </Card>  
                            </div>
                        )
                    })
                }
            </>
        )
    }

    return (
        <ComponentAdminContent 
            title={<><SVGIcon icon="apps" width="30" height="30" /> Aplicativos <b>{ ActiveStore.platform ? ` - ${ActiveStore.platform.name}` : ''}</b></>}
            subtitle={<>TODOS OS APPS COM <b><u>{process.env.REACT_APP_TRIAL_DAYS} DIAS GRÁTIS</u></b></>}
        >
            <div className="apps-list">
                {
                    loading
                    ? <p>Carregando...</p>
                    : <div className="row"><AppList /></div>    
                }
            </div>
        </ComponentAdminContent>
    )
}

export default PageAdminAppList;