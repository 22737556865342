import React, { lazy, Suspense, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import api from '../../services/api';
import ComponentAdminContent from '../../components/Admin/AdminContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { history } from '../../services/history';
import { UserContext } from '../../context/UserContext';
import SwitchStatus from '../../components/UI/SwitchStatus';

const PageAdminEditor = () => {

    const { appStoreId } = useParams();
    const { ActiveStore, User } = useContext(UserContext);

    const [loading, setLoading] = useState(true)
    const [appStore, setAppStore] = useState({})
    const [appName, setAppName] = useState(<FontAwesomeIcon icon={['fas', 'circle-notch']} spin />)
    const [AppTemplate, setAppTemplate] = useState(<FontAwesomeIcon icon={['fas', 'circle-notch']} spin />)
    
    useEffect(() => {
        async function fetchData(){
            await api.get(`/app-store/${appStoreId}`)
                .then( resp => {
                    
                    const { data } = resp;
                    
                    if( !data.active || data.store._id !== ActiveStore._id ){
                        toast.error('Loja sem acesso ao app')
                        history.push('/admin/apps');
                    }

                    if( data._id ){
                        setAppStore(data)

                        const { app } = data;
                        const { platform } = app;
                        
                        setAppTemplate(lazy( async () => {
                            let temp_app = null;

                            const platformSlug = ( platform !== null && 'slug' in platform && platform['slug'] !== null ) ? platform['slug'] : 'ALL';

                            console.log(platformSlug)

                            try{
                                console.log(`App folder - ./Apps/${(platformSlug).toUpperCase()}/${app.slug}`)
                                temp_app = await import(`./Apps/${(platformSlug).toUpperCase()}/${app.slug}`);
                            }catch(err){
                                temp_app = await import(`./Apps/NotFound`);;
                            }
                            return temp_app
                        }))

                        try {
                            setAppName(data.app.name.pt)
                        } catch (error) {
                            setAppName('Notfound')
                        }
                    }else{
                        toast.error('Erro ao buscar dados');
                        history.push('/admin/apps');
                    }
                    
                }).catch( err => {
                    try {
                        // const { error } = err.response.data;
                        // toast.error(error);
                        console.log('erro user', err);

                    } catch (err) {
                        console.log('erro try user', err);
                    }
                    history.push('/admin/apps');
                }).then( () => {
                    setLoading(false);
                })
        }
        if( appStoreId && ActiveStore && User ){
            fetchData();
        }
    }, [appStoreId, ActiveStore, User]);

    return (
        <ComponentAdminContent 
            title={<div className="d-flex align-items-center">
                <FontAwesomeIcon icon={['fas', 'magic']} fixedWidth className="text-primary me-2" /> Editor - <b>{appName}</b> 
                <SwitchStatus name="inUse" successMsg='Status do App alterado!' action={`/app-store/${appStore._id}/status`} label="" offColorDanger={true} size="lg" className="ms-4" value={appStore.inUse} onText="ativo" offText="pausado" />
            </div>}
            subtitle=""
            titleExtra={
                <div className="d-flex justify-content-between align-items-center">
                    <Link to="/admin/apps" className="btn btn-default btn-sm"><FontAwesomeIcon icon={['fas', 'long-arrow-alt-left']} fixedWidth className="me-1" /> Voltar</Link>
                </div>
            }
        >
            {
                loading
                ? <p><FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> aguarde...</p>
                : 
                    <Suspense fallback={<FontAwesomeIcon icon={['fas', 'circle-notch']} spin />}>
                        <AppTemplate appStore={appStore} />
                    </Suspense>
            }
        </ComponentAdminContent>
    )
}

export default PageAdminEditor;