import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const PageError404 = () => {
    return (
        <div className="fullscreen-bg">
            <div id="wrapper-404" className="d-flex align-items-center justify-content-center">
                <div className="container-fluid page-error">
                    <h1>
                        <span className="title">
                            <span className="number left">404</span> <span className="text">Oops! <br />Page Not Found</span>
                        </span>
                    </h1>
                    <p>The page you were looking for could not be found, please contact us to report this issue.</p>
                    <p>You can also use search form below to find the page you are looking for.</p>

                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <Button onClick={() => window.history.go(-1)} variant="outline-light" className="me-1"><FontAwesomeIcon icon={['fas', 'arrow-left']} />  <span>Go Back</span></Button>
                        <Link to="/admin" className="btn btn-outline-light ms-1"><FontAwesomeIcon icon={['fas', 'home']} /> <span>Home</span></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageError404;