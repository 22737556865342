import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import api from '../../../services/api';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

import brandImg from '../../../assets/img/huamake-white.png';
import ComponentFormsRegister from '../../../components/Forms/ComponentFormsRegister';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';


SwiperCore.use([Navigation, Pagination]);


const PageAppDetail = props => {

    const { appSlug } = useParams();
    const queryString = new URLSearchParams(props.location.search);

    const utm_source = queryString.get('utm_source');

    const [loadingApp, setLoadingApp] = useState(false);
    const [appIsOk, setAppIsOk] = useState(false);
    const [app, setApp] = useState(null);
    const [appName, setAppName] = useState('');
    const [pagePrimaryColor, setPagePrimaryColor] = useState('#ED3792');


    const videoOpts = {
        width: '860',
        height: '524',
        playerVars: {
            controls: 0,
            autoplay: 0,
        },
    }

    useEffect(() => {
        async function fetchData(){
            await api.get(`/pub/app/${appSlug}`, {})
                    .then( resp => {
                        const { data } = resp;
                        setApp(data);
                        setAppName(data.name.pt);

                        localStorage.setItem(`utm_platform_${data._id}`, utm_source)
                        if( data.descriptionColor ){
                            setPagePrimaryColor(data.descriptionColor)
                        }
                        setAppIsOk(true);
                    })
                    .catch( err => {
                        toast.error('Erro ao buscar app');
                        setAppIsOk(false);
                        setApp({});
                    })
                    .then( () => {
                        
                    })
        }
        if( !loadingApp && appSlug ){
            setLoadingApp(true);
            fetchData();
        }
    }, [loadingApp, appSlug, utm_source]);


    const ScreenShots = () => {
        const screenShots = app.screenShots;
        if( !screenShots || !screenShots.length ){
            return <></>
        }

        return <div className="app-detail-page-screen-shots mt-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={1}
                                autoplay={true}
                                navigation
                                pagination={{ clickable: true }}
                            >
                                {
                                    screenShots.map( (image, key) => {
                                        return  <SwiperSlide key={key}>
                                                    <img className="d-block w-100" src={image} alt="" />
                                                </SwiperSlide>
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
    }

    return (
        <div className="app-detail-page">
            <div className="app-detail-page-header sticky-top py-3">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="app-detail-page-brand-box d-flex align-items-center">
                            <h1 className="app-detail-page-brand-img me-4">
                                <img src={brandImg} alt="" width="148" height="27" />
                                <b className="sr-only">Huamake</b>
                            </h1>    
                            <b className="app-detail-page-brand-msg">as melhores soluções e apps para revolucionar seu e-commerce!</b>
                        </div>
                        <b className="app-detail-page-header-security"><FontAwesomeIcon icon={['fas', 'lock']} fixedWidth /> 100% Seguro</b>
                    </div>        
                </div>
            </div>

            {
                ( !appIsOk )
                ?   <div className="loading">Buscando...</div>
                :
                    <>
                        <div className="app-detail-page-fullbanner">
                            { ( app.photo_cover && app.photo_cover.pt ) ? <img src={app.photo_cover.pt} alt="" /> : '' }
                            <div className="container">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-12 col-md-5 col-info">
                                        <div className="app-detail-page-app-name" style={{'background': pagePrimaryColor}}>{ appName }</div>
                                        <div className="app-detail-page-app-short-description">{ (app.shortDescription) ? app.shortDescription.pt : '' }</div>
                                    </div>

                                    <div className="col-12 col-md-3 col-form">
                                        <h3>Novo por aqui?</h3>
                                        <p>Crie sua conta e ganhe <b style={{'background': pagePrimaryColor}}>{process.env.REACT_APP_TRIAL_DAYS} dias grátis</b></p>
                                        <div className="col-form-border">
                                            <ComponentFormsRegister hideLoginButton={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="app-detail-page-description">
                            <div className="container">
                                { (app.description) ? app.description.pt : '' }

                                <ScreenShots />
                            </div>
                        </div>
                        {
                            ( app.demoVideo )
                            ?   <div className="app-detail-page-video">
                                    <div className="container">
                                        <h3>Assista ao vídeo e saiba</h3>
                                        <p>Como funciona o app <b style={{'color': pagePrimaryColor}}>{ appName }</b>.</p>

                                        <div className="app-detail-page-video-border">
                                            <YouTube
                                                videoId={app.demoVideo}
                                                id={`video-${app.demoVideo}`}
                                                className=""
                                                containerClassName="ratio ratio-16x9"
                                                opts={videoOpts}
                                            />
                                        </div>
                                        <div className="app-detail-page-video-shadown"></div>
                                    </div>

                                    <div className="app-detail-page-video-info text-center" style={{'background': pagePrimaryColor}}>
                                        <div className="container">
                                            <FontAwesomeIcon icon={['fab', 'youtube']} />
                                            <p>Para a instalação e personalização do { appName } assista nossos tutoriais ou se necessário peça suporte de uma agência.</p>

                                            <a href="https://www.youtube.com/watch?v=Ruybv2KX4Jo&amp;list=PLURIgSDI4D76e7f00WS4xpbDCP36iAGKD&amp;ab_channel=HuamakeTecnologia" target="_blank" rel="noreferrer">PLAYLIST DE TUTORIAIS</a>
                                        </div>
                                    </div>
                                </div>
                            : <></>
                        }
                        <div className="app-detail-page-final">
                            <div className="container">
                                <div className="d-flex align-items-center">
                                    { 
                                        ( app.photo && app.photo.pt ) 
                                        ?   <div class="app-detail-page-final-photo">
                                                <img src={app.photo.pt} alt={ appName } title={ appName } />
                                            </div>
                                        : '' 
                                    }
                                    <div class="app-detail-page-final-msg">
                                        <h3>Não perca mais tempo</h3>
                                        <p style={{'color': pagePrimaryColor}}>Revolucione seu e-commerce com nosso app agora mesmo!</p>

                                        <Link to={`/admin/apps/${app._id}/install`} style={{'background': pagePrimaryColor}}>Quero na minha loja</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
            <div className="app-detail-page-copyright">
                <div className="container">
                    <p>Copyright © 2021 Hua.make - Todos os direitos reservados</p>
                </div>
            </div>
        </div>
    )
}

export default PageAppDetail;