import React, { useState, useContext } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../../services/api';
import toast from 'react-hot-toast';
import { InputField, SelectField } from '../../../../components/Formik/Field';
import ComponentsFormikFieldOptionsPlatforms from '../../../../components/Formik/DinamicOptions/Platforms';
import { UserContext } from '../../../../context/UserContext';
import { history } from '../../../../services/history';

const NS_ID = process.env.REACT_APP_PLATFORM_NS_ID;

const StoreCreateForm = (props) => {

    const { setIsNuvemShop } = props;

    const [isLoading, setLoading] = useState(false);
    const { setActiveStoreId } = useContext(UserContext);

    const handleCepBlur = async (e, setFieldValue) => {
        try {
            const CEP = (e.target.value).toString().replace(/\./g, '').replace(/-/g, '').replace(/_/g, '').trim();
            if( CEP !== undefined && CEP !== null && CEP.length === 8 ){
                axios.get(`//viacep.com.br/ws/${CEP}/json/`)
                    .then( res => {
                        const { data : { logradouro, bairro, complemento, localidade, uf } } = res;
                        setFieldValue('businessAddress.address1', logradouro )
                        setFieldValue('businessAddress.address3', bairro )
                        setFieldValue('businessAddress.address4', complemento )
                        setFieldValue('businessAddress.city', localidade )
                        setFieldValue('businessAddress.state', uf )
    
                        toast.success('CEP encontrado.')
                    })
                    .catch( err => {
                        toast.error('CEP não encontrado.')
                    });
            }else{
                toast.error('CEP inválido.')
            }
        } catch (error) {
            toast.error('Erro ao buscar CEP.')
        }
    }
    
    const handleSubmit = async (values)=>{
        
        setLoading(true);
        await api.post('/stores', values)
            .then(async resp => {
                const { _id } = resp.data;
                toast.success('Loja criada com sucesso');

                // await setReloadUser(true);
                await setActiveStoreId(_id);

                history.push('/admin/apps')

            }).catch( err => {
                const { error } = err.response.data;
                toast.error(error);
            }).then( () => {
                setLoading(false);
            });
    };

    const handlePlatformChange = (e, setFieldValue) => {
        if( e.target.value === NS_ID ){
            setIsNuvemShop(true);
        }else{
            setIsNuvemShop(false);
        }
        setFieldValue('platform', e.target.value);
    }
    
    const FormValidation = yup.object().shape({
        name: yup.string().min(3).required(),
        domain: yup.string().url().required(),
        type: yup.string(),
        logo: yup.string(),
        platform: yup.string().required(),
        language: yup.string().required(),
        social: yup.object().shape({
            facebook: yup.string(),
            twitter: yup.string(),
            instagram: yup.string(),
            pinterest: yup.string(),
            blog: yup.string(),
        }),
        businessInfo: yup.object().shape({
            type: yup.string().required(),
            name: yup.string().required(),
            email: yup.string().email().required(),
            document: yup.string().required(),
            phone: yup.string().required(),
        }),
        businessAddress: yup.object().shape({
            address1: yup.string().required(), // logradouro
            address2: yup.string().required(), // numero
            address3: yup.string(), // bairro
            address4: yup.string(), // complemento
            cep: yup.string().required(),
            city: yup.string().required(),
            state: yup.string().max(2).required(),
            country: yup.string().required(),
        }),
    })

    return (
        <Formik 
            initialValues={{
                name: '',
                domain: '',
                type: '',
                logo: '',
                platform: '',
                language: 'PT',
                social: {
                    facebook: '',
                    twitter: '',
                    instagram: '',
                    pinterest: '',
                    blog: '',
                },
                businessInfo: {
                    type: '',
                    name: '',
                    email: '',
                    document: '',
                    phone: '',
                },
                businessAddress: {
                    address1: '', // logradouro
                    address2: '', // numero
                    address3: '', // bairro
                    address4: '', // complemento
                    cep: '',
                    city: '',
                    state: '',
                    country: '',
                },
            }} 
            onSubmit={handleSubmit} 
            validationSchema={FormValidation}
            enableReinitialize={true}
        >
            {({ errors, touched, setFieldValue }) => (
                <Form className="form text-left">
                    <Card className="my-store">
                        <Card.Body>
                            <div className="row">
                                
                                {/* COLUNA DADOS LOJA */}
                                <div className="col-md-6">
                                    <legend>Dados da Loja</legend>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <SelectField name="platform" label="Plataforma" onChange={e=>handlePlatformChange(e, setFieldValue)} >
                                                <ComponentsFormikFieldOptionsPlatforms />
                                            </SelectField>
                                        </div>
                                        <div className="col-md-7">
                                            <InputField type="url" name="domain" label="Dominio/URL" />
                                        </div>
                                    </div>
                                    <InputField type="text" name="name" label="Name" />
                                    <div className="row">
                                        <div className="col-md-8">
                                            <SelectField name="type" label="Segmento" >
                                                <option value="">Escolha uma alternativa...</option>
                                                <option value="clothing">Moda e Vestuário</option>
                                                <option value="clothing_accesories">Acessórios de moda</option>
                                                <option value="health_beauty">Saúde e Beleza</option>
                                                <option value="home_garden">Casa e Jardim</option>
                                                <option value="gifts">Presentes</option>
                                                <option value="food_drinks">Comida e Bebida</option>
                                                <option value="electronics_it">Tecnologia</option>
                                                <option disabled="" value="">---</option>
                                                <option value="antiques">Antiguidades</option>
                                                <option value="art">Artesanato</option>
                                                <option value="cars">Automóveis</option>
                                                <option value="industrial">Construção e Indústria</option>
                                                <option value="sports">Esportes e Recreação</option>
                                                <option value="digital">Bens digitais</option>
                                                <option value="education">Educação</option>
                                                <option value="office_supplies">Material de escritório</option>
                                                <option value="jewelry">Jóias e Relógios</option>
                                                <option value="toys">Jogos e Hobbies</option>
                                                <option value="books">Livros e Revistas</option>
                                                <option value="pets">Pets e Mascotes</option>
                                                <option value="music_movies">Música e Cinema</option>
                                                <option value="services">Serviços</option>
                                                <option value="travel">Turismo</option>
                                                <option value="erotics">Sex Shop</option>
                                                <option value="bookstore_graphic">Livraria e Gráfica</option>
                                                <option value="equipment_machines">Equipamentos e Maquinário</option>
                                                <option value="other">Outra</option>
                                            </SelectField>
                                        </div>
                                        <div className="col-md-4">
                                            <SelectField name="language" label="Idioma" >
                                                <option value="">Selecione</option>
                                                <option value="PT">PT</option>
                                                <option value="EN">EN</option>
                                                <option value="ES">ES</option>
                                            </SelectField>
                                        </div>
                                    </div>
                                    <InputField type="text" name="logo" label="Logo" />
                                </div>

                                {/* COLUNA SOCIAL */}
                                <div className="col-md-6">
                                    <legend>Social</legend>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <InputField type="text" name="social.facebook" label="Facebook" />
                                        </div>
                                        <div className="col-md-4">
                                            <InputField type="text" name="social.twitter" label="Twitter" />
                                        </div>
                                        <div className="col-md-4">
                                            <InputField type="text" name="social.instagram" label="Instagram" />
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputField type="text" name="social.pinterest" label="Pinterest" />
                                        </div>
                                        <div className="col-md-6">
                                            <InputField type="text" name="social.blog" label="Blog" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-5 mb-5"/>
                            <h4 className="h3 mb-4">Dados do Responsavel ou Empresa <small>(Dados utilizados para gerar <b>NFSe</b>.)</small></h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <legend>Informações Basicas</legend>
                                    <InputField type="text" name="businessInfo.name" label="Nome/Razão Social" />
                                    
                                    <div className="row">
                                        <div className="col-md-7">
                                            <InputField type="text" name="businessInfo.email" label="E-mail" />
                                        </div>
                                        <div className="col-md-5">
                                            <InputField type="text" name="businessInfo.phone" label="Telefone" mask="(99) 99999-9999" />
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-4">
                                            <SelectField name="businessInfo.type" label="Tipo de Documento" >
                                                <option value="">Selecione</option>
                                                <option value="CNPJ">CNPJ</option>
                                                <option value="CPF">CPF</option>
                                                <option value="DNI">DNI</option>
                                                <option value="CUIL">CUIL</option>
                                                <option value="CUIT">CUIT</option>
                                            </SelectField>
                                        </div>   
                                        <div className="col-md-8">
                                            <InputField type="text" name="businessInfo.document" label="Documento" />
                                        </div>
                                    </div>   
                                </div>
                            
                                <div className="col-md-6">
                                    <legend>Endereço de Cobrança</legend>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <InputField type="text" name="businessAddress.cep" mask="99.999-999" label="CEP" onBlur={e => handleCepBlur(e, setFieldValue)} />
                                        </div>
                                        <div className="col-md-6">
                                            <InputField type="text" name="businessAddress.address1" label="Logradouro"  />
                                        </div>
                                        <div className="col-md-2">
                                            <InputField type="text" name="businessAddress.address2" label="Numero" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <InputField type="text" name="businessAddress.address3" label="Bairro" />
                                        </div>
                                        <div className="col-md-6">
                                            <InputField type="text" name="businessAddress.address4" label="Complemento" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <InputField type="text" name="businessAddress.state" label="UF" />
                                        </div>
                                        <div className="col-md-4">
                                            <InputField type="text" name="businessAddress.city" label="Cidade" />
                                        </div>
                                        <div className="col-md-4">
                                            <InputField type="text" name="businessAddress.country" label="Pais" />
                                        </div>
                                    </div>
                
                                </div>
                            </div>

                            <div className="d-flex justify-content-center mt-5">
                                <Button variant="primary" size="lg" className="cta-submit btn-block" type="submit" disabled={isLoading}>
                                    {isLoading ? <FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> : 'Cadastrar Loja'}
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Form>
            )}
    </Formik >
    )
}

export default StoreCreateForm