import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import ComponentAdminContent from '../../../components/Admin/AdminContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../services/api';
import { UserContext } from '../../../context/UserContext';
import { Link } from 'react-router-dom';
import { Accordion, Button } from 'react-bootstrap';
import parse from 'html-react-parser';
import { InputField, SelectField } from '../../../components/Formik/Field';

import * as yup from 'yup';
import { Formik, Form } from 'formik';
import toast from 'react-hot-toast';

const PageAdminHelpTopics = () => {

    const [loadingTopics, setLoadingTopics] = useState(true);
    const [topics, setTopics] = useState([]);
    const { User } = useContext(UserContext);

    useEffect(() => {
        async function fetchData(){
            setLoadingTopics(false);
            
            await api.get(`/help/topics`)
                .then( resp => {
                    const { data } = resp;

                    if( !data || 'error' in data ){
                        setTopics([]);
                        return false;
                    }

                    setTopics(data);

                }).catch( err => {
                    try {
                        console.log('erro user', err);
                    } catch (err) {
                        console.log('erro try user', err);
                    }
                });
        }
        if( loadingTopics ){
            fetchData();
        }
    }, [loadingTopics, setTopics]);


    function ListTopics(){
        if( loadingTopics ){
            return <p className="alert alert-warning"><FontAwesomeIcon icon={['fas', 'circle-notch']} fixedWidth spin /> Aguarde, buscando topicos</p>
        }

        if( !topics.length ){
            return <p className="alert alert-warning"><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} fixedWidth /> Nenhum topico encontrado</p>
        }
        return (
            <div className="help-box--topics-list">
                {
                    topics.map( (value, key) => {
                        return (
                            <div className="help-box--topics-list-item" key={key}>
                                <Link to={`/admin/help/${value._id}`} className="d-flex justify-content-between align-items-center help-box--topics-button">
                                    <span>{value.name.pt}</span>
                                    <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} fixedWidth className="ms-2" />
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <ComponentAdminContent
            title={<div className="d-flex align-items-center"><FontAwesomeIcon icon={['fas', 'question-circle']} fixedWidth className="text-primary" /> <span>Ajuda &amp; Suporte</span></div>}
            subtitle=""
        >
            <div className="help-box">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="help-box--header d-flex justify-content-between align-items-center">
                            <div className="help-box--title">
                                <h2 className="h6 font-weight-normal">Olá, {User.name}</h2>
                                <h3 className="h4">Com o que podemos ajudar?</h3>
                            </div>
                            <div className="help-box--search">

                            </div>
                        </div>

                        <div className="">
                            <p className="alert alert-danger"><FontAwesomeIcon icon={['fab', 'youtube']} fixedWidth className="mr-1" /> Veja nossa playlist de videos tutoriais <a href="https://www.youtube.com/watch?v=Ruybv2KX4Jo&amp;list=PLURIgSDI4D76e7f00WS4xpbDCP36iAGKD" target="_blank" rel="noreferrer" className="text-danger fw-bold">clicando aqui</a>.</p>
                        </div>
                    
                        <div className="help-box--topics mt-5">
                            <ListTopics />
                        </div>
                    </div>
                </div>
            </div>
        </ComponentAdminContent>
    )
}

const PageAdminHelpTopicsView = () => {

    const { helpTopicId } = useParams();

    const [loadingItems, setLoadingItems] = useState(true);
    const [questions, setQuestions] = useState({topic: null, items: []});
    
    useEffect(() => {
        async function fetchData(){
            setLoadingItems(false);
            
            await api.get(`/help/topics/${helpTopicId}/items`)
                .then( resp => {
                    const { data } = resp;

                    if( !data || 'error' in data ){
                        setQuestions({topic: null, items: []});
                        return false;
                    }

                    console.log(data)
                    setQuestions(data);

                }).catch( err => {
                    try {
                        console.log('erro user', err);
                    } catch (err) {
                        console.log('erro try user', err);
                    }
                });
        }
        if( loadingItems && helpTopicId ){
            fetchData();
        }
    }, [loadingItems, helpTopicId, setLoadingItems]);

    function ListItems(){
        if( loadingItems ){
            return <p className="alert alert-warning"><FontAwesomeIcon icon={['fas', 'circle-notch']} fixedWidth spin /> Aguarde, buscando perguntas</p>
        }

        if( !questions.items.length ){
            return <p className="alert alert-warning"><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} fixedWidth /> Nenhuma pergunta encontrado</p>
        }
        return (
            <Accordion>
                {
                    questions.items.map( (value, key) => {
                        return (
                            <Accordion.Item eventKey={key} key={key}>
                                <Accordion.Header>
                                    <span className="d-block">
                                        <b className="d-block">{value.question.pt}</b>
                                        <small className="d-block">{value.description.pt}</small>
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {parse(value.answer.pt)}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
        )
    }

    return (
        <ComponentAdminContent
            title={<div className="d-flex align-items-center"><FontAwesomeIcon icon={['fas', 'question-circle']} fixedWidth className="text-primary" /> <span>Ajuda &amp; Suporte</span></div>}
            subtitle={ ( !loadingItems && questions.topic ) ? questions.topic.name.pt : ''}
            titleExtra={
                <Link to={`/admin/help`} className="btn btn-default btn-sm"><FontAwesomeIcon icon={['fas', 'arrow-left']} className="me-1" /> VER TOPICOS</Link>
            }
        >
            <div className="help-box">
                <div className="row">
                    <div className="col-12">
                        <div className="help-box--header d-flex justify-content-between align-items-center">
                            <div className="help-box--title">
                                { 
                                    ( !loadingItems && questions.topic )
                                    ? 
                                        <>
                                            <h2 className="h6 font-weight-normal">{ questions.items ? `${questions.items.length} perguntas` : `sem perguntas` }</h2>
                                            <h3 className="h4">Separamos algumas duvidas sobre <b><u>{questions.topic.name.pt}</u></b></h3>
                                        </>
                                    : <></>
                                }
                            </div>
                            <div className="help-box--search">

                            </div>
                        </div>
                    
                        <div className="help-box--topics mt-5">
                            <ListItems />
                        </div>

                        <div className="help-box--contact mt-5">
                            <p><b>Não encontrou o que procurava?</b> abra um chamado <Link to="/admin/help/contact-form">clicando aqui</Link> que iremos responder o mais rápido possivel.</p>
                        </div>
                    </div>
                </div>
            </div>
        </ComponentAdminContent>
    )
}

const PageAdminHelpContactForm = () => {

    const [sendLoading, setSendLoading] = useState(false);
    const [result, setResult] = useState(false);
    const [loadingApps, setLoadingApps] = useState(true);
    const [apps, setApps] = useState([]);
    const { ActiveStore, User } = useContext(UserContext);

    const initialValues = {
        name: '',
        whatsapp: '',
        subject: '',
        message: '',
    }
    const FormValidation = yup.object().shape({
        name: yup.string().min(3).required(),
        whatsapp: yup.string().min(10).required(),
        subject: yup.string().min(3).required(),
        message: yup.string().min(10).required(),
    })

    useEffect(() => {
        async function fetchData(){
            setLoadingApps(false);
            await api.get(`/apps/platform/${ActiveStore.platform._id}/select`)
                .then( resp => {
                    const { data } = resp;

                    if( !data || 'error' in data ){
                        setApps([]);
                        return false;
                    }

                    setApps(data);

                }).catch( err => {
                    try {
                        console.log('erro apps', err);
                    } catch (err) {
                        console.log('erro try apps', err);
                    }
                });
        }
        if( loadingApps && ActiveStore ){
            fetchData();
        }
    }, [loadingApps, setApps, ActiveStore]);

    const handleSubmit = async (values) => {
        setSendLoading(true);
        await api.post('/help/contact', {store: ActiveStore._id, user: User._id, ...values})
            .then(async resp => {
                
                const { data } = resp;
                
                if( '_id' in data ){
                    toast.success('Ticket criado com sucesso.');
                    setResult(true);
                }

            }).catch( err => {
                const { error } = err.response.data;
                toast.error(error);
            }).then( () => {
                setSendLoading(false);
            });
    }

    const SelectAppsOptions = () => {
        if( loadingApps ){
            return <option value="">Buscando apps...</option>
        }

        if( !apps.length ){
            return <option value="">Nenhum app encontrado</option>
        }

        let options = [];
        for( let x = 0; x < apps.length; x++ ){
            options.push(`<option value="${apps[x]['_id']}">${apps[x]['name']['pt']}</option>`);
        }

        return parse(options.join(''));
    }

    const ResultContact = () => {
        return (
            <div>
                <p className="alert alert-success">Recebemos sua mensagem, nossa equipe entrará em contato o mais rápido possivel!</p>
            </div>
        )
    }
    const FormContact = () => {
        return (
            <Formik 
                initialValues={initialValues}
                onSubmit={handleSubmit} 
                validationSchema={FormValidation}
                enableReinitialize={true}
            >
                {() => (
                    <Form className="form text-left">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <InputField type="text" name="name" label="Nome" />
                            </div>
                            <div className="col-12 col-md-6">
                                <InputField type="text" name="whatsapp" label="Telefone/Whatsapp" mask="(99) 99999-9999" />
                            </div>
                            <div className="col-12 col-md-12">
                                <InputField type="text" name="subject" label="Assunto" />
                            </div>
                            <div className="col-12 col-md-12">
                                <SelectField type="text" name="app" label="Aplicativo">
                                    <option value="">Selecione</option>
                                    <SelectAppsOptions />
                                </SelectField>
                            </div>
                            <div className="col-12">
                                <InputField component="textarea" name="message" label="Mensagem" />
                            </div>
                        </div>

                        <Button type="submit" variant="primary" size="lg" className="mt-3">
                            {sendLoading ? <FontAwesomeIcon icon={['fas', 'circle-notch']} spin /> : 'Abrir chamado'}
                        </Button>
                    </Form>
                )}
            </Formik>  
        )
    }

    return (
        <ComponentAdminContent
            title={<div className="d-flex align-items-center"><FontAwesomeIcon icon={['fas', 'question-circle']} fixedWidth className="text-primary" /> <span>Ajuda &amp; Suporte</span></div>}
            subtitle="Abrir chamado"
            titleExtra={
                <Link to={`/admin/help`} className="btn btn-default btn-sm"><FontAwesomeIcon icon={['fas', 'arrow-left']} className="me-1" /> VER TOPICOS</Link>
            }
        >
            <div className="help-box">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <p className="alert alert-warning">
                            <FontAwesomeIcon icon={['fab', 'youtube']} fixedWidth className="mr-1" /> Antes de abrir um chamado veja nossa playlist de videos tutoriais <a href="https://www.youtube.com/watch?v=Ruybv2KX4Jo&amp;list=PLURIgSDI4D76e7f00WS4xpbDCP36iAGKD" target="_blank" rel="noreferrer" className="text-danger fw-bold">clicando aqui</a>.
                            <br />
                            Criamos um videos rápidos e simples para explicar cada funcionalidade, se mesmo assim estiver com problemas, estaremos prontos para auxiliar da melhor forma possivel (:
                        </p>
                        { 
                            ( result )
                            ? <ResultContact />
                            : <FormContact />
                        }
                          
                    </div>
                </div>
            </div>
        </ComponentAdminContent>
    )
}

export { PageAdminHelpTopics, PageAdminHelpTopicsView, PageAdminHelpContactForm }